<template>
  <div class="user-info-widget">
    <div class="d-flex">
      <div class="media-support-user-img mr-3">
        <b-img rounded="circle" fluid :src="avatar" :alt="user.fullName" />
      </div>
      <div class="media-support-info text-left">
        <div class="mb-0">
          <div>Listing Provided By</div>
          <h5>{{ user.fullName }}</h5>
          <div class="user-location"> Agoura hills - ZIP 91301</div>
        </div>
        <div>{{ user.role }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserInfoStyle2',
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      defaultAvatar: require('@/assets/images/user/04.jpg')
    }
  },
  computed: {
    avatar () {
      return this.user.avatar ? this.user.avatar : this.defaultAvatar
    }
  }
}
</script>
