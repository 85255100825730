<template>
  <div>
    <b-tooltip custom-class="homeiz-tooltip" placement="top" :target="`edit-delete-${id}-${this._uid}`" triggers="hover" v-if="tooltipText">
      {{ tooltipText }}
    </b-tooltip>
    <b-dropdown :id="`edit-delete-${id}-${this._uid}`" variant="outline" class="comment-action-button" no-caret>
      <template #button-content>
        &nbsp;<i class="ri-more-fill"></i>
      </template>
      <slot name="button-items"></slot>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  name: 'ButtonWithTooltip',
  props: {
    tooltipText: {
      type: String,
      default: null
    },
    id: {
      type: Number,
      required: true
    }
  }
}
</script>

<style lang="scss">
  .tooltip {
    .arrow {
      display: none;
    }
  }

  .homeiz-tooltip {
    .tooltip-inner {
      background: var(--iq-homeiz-blue);
      color: #187ff2;
    }
  }
</style>
