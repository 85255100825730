<template>
    <div class="cash-offer">
    <b-row>
      <b-col md="12" class="d-flex justify-content-center currency-input">
        <div class="offer-amount">
          {{ $n(data.amount, `currencyNoCents`) }}
       </div>
      </b-col>
    </b-row>
    <b-row class="d-flex">
      <b-col md="6">
        <UserInfoStyle2 :user="user" />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="mt-2">
        <ListingInfo :listing="listing" />
      </b-col>
    </b-row>
    <b-row class="mt-2 mb-4" v-if="data.message">
      <b-col md="12" class="text-left">
        {{ data.message }}
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <p class="terms-text">Until both parties have come to an agreement on all the contract terms and actually signed the purchase agreement such that you're in contract, neither of you are legally bound to anything, and you can retract your cash offer from any reason.</p>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="d-flex justify-content-between">
        <b-button class="w-100" @click="retract" variant="primary">
          Retract cash offer
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import * as systemNotificationActions from '@/store/modules/system-notification/types/actions'
import UserInfoStyle2 from '@/components/homeiz/user/UserInfoStyle2'
import ListingInfo from '@/components/homeiz/realEstate/listing/ListingInfo'

export default {
  name: 'CashOfferRetract',
  components: {
    UserInfoStyle2,
    ListingInfo
  },
  inject: ['authUser'],
  props: {
    data: {
      type: Object,
      required: true
    },
    listing: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
    }
  },
  methods: {
    ...mapActions('systemNotification', {
      setDoneNotification: systemNotificationActions.SET_DONE_NOTIFICATION
    }),
    retract () {
      this.setDoneNotification(`Your cash offer in the amount of ${this.$n(this.data.amount, `currencyNoCents`)} was successfully retracted.`)
      this.$emit('retractOffer')
    }
  },
  computed: {
    avatar () {
      return this.user.avatar ? this.user.avatar : this.defaultAvatar
    },
    user () {
      return this.authUser
    }
  }
}
</script>

<style lang="scss">
.offer-amount {
  font-size: 36px;
  color: var(--iq-success);
  font-weight: bolder;
}

.cash-offer {
  label {
    color: var(--iq-homeiz-royal-blue) !important;
  }

  .offer-image {
    img {
      max-width: 260px;
    }
  }
}

.terms-text {
  font-size: 11px;
}
</style>
