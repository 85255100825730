<template>
  <div class="cash-offer">
    <b-row>
      <b-col md="12" class="d-flex justify-content-center currency-input">
        <div>
          <b-form-group>
            <VueNumber class="form-control" v-model="form.amount" :class="!error ? 'is-invalid' : ''" />
            <div class="d-flex justify-content-between w-100">
              <div class="invalid-feedback d-block" v-if="!error">
                Please enter your offer.
              </div>
            </div>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <b-row class="d-flex">
      <b-col md="6">
        <UserInfoStyle2 :user="user" />
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col md="12">
        <CashOfferHistory />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="mt-1">
        <ListingInfo :listing="listing" />
      </b-col>
    </b-row>
    <b-row class="mt-2 mb-2">
      <b-col md="12">
        <FileUploader
          subTitle="We accept PDF, DOC (100mb file size)"
          accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="text-left">
        <FormTextAreaLimited v-model="form.message" :rows="1" label="Message (optional)" :limit="250"/>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <p class="terms-text">By submitting a cash offer, your are only submitting an initial request to start the buying and selling process, so there are no obligations.</p>
        <p class="terms-text">All cash offers will expired within 72 hours from the time it was received.</p>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mt-2" md="12">
        <b-button @click="nextStep" :disabled="!$v.form.amount.required" size="lg" variant="primary" class="w-100 review">
          Review Offer
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import wizardSteps from '@/mixins/wizardSteps'
import FileUploader from '@/components/homeiz/common/form/FileUploader'
import FormTextAreaLimited from '@/components/homeiz/common/form/FormTextAreaLimited'
import CashOfferHistory from './CashOfferHistory'
import UserInfoStyle2 from '@/components/homeiz/user/UserInfoStyle2'
import ListingInfo from '@/components/homeiz/realEstate/listing/ListingInfo'

export default {
  name: 'CashOfferStep1',
  components: {
    FileUploader,
    FormTextAreaLimited,
    CashOfferHistory,
    UserInfoStyle2,
    ListingInfo
  },
  mixins: [wizardSteps],
  inject: ['authUser'],
  props: {
    listing: {
      type: Object,
      required: true
    }
  },
  validations: {
    form: {
      amount: { required }
    }
  },
  data () {
    return {
      formSubmitted: false,
      form: {
        amount: '',
        message: null
      }
    }
  },
  computed: {
    avatar () {
      return this.user.avatar ? this.user.avatar : this.defaultAvatar
    },
    user () {
      return this.authUser
    },
    error () {
      return this.$v.form.amount.required
    }
  }
}
</script>

<style lang="scss">
.cash-offer {
  label {
    color: var(--iq-homeiz-royal-blue) !important;
  }

  .offer-image {
    img {
      max-width: 260px;
    }
  }

  .currency-input {
    div {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
  }

  input {
    &.form-control {
      width: 60%;
      text-align: center;
      padding-right: 12px !important;
      font-size: 30px !important;
      border: 1px solid transparent !important;
      border-bottom: 1px solid var(--iq-primary) !important;
      border-radius: 0 !important;

      &.is-invalid {
        border-bottom-color: #f00 !important;
      }
    }
  }

  textarea {
    border-radius: 20px !important;
  }

  .btn-review {
    height: 60px;
  }
}

.terms-text {
  font-size: 11px;
}
</style>
