<template>
  <div>
    <b-form-group>
      <label>{{ label }} <span v-if="required">*</span></label>
      <b-form-textarea
        v-model="inputText"
        :rows="rows"
        max-rows="6"
        :state="!error"
        :maxlength="limit"
        trim
      />
      <div class="d-flex justify-content-between">
        <div class="invalid-feedback d-block" v-if="error">
          {{ errorMessage }}
        </div>
        <div class="max-length w-100">Max: {{ symbolsLeft }} characters</div>
      </div>
      </b-form-group>
    </div>
</template>

<script>
import { requiredIf, maxLength } from 'vuelidate/lib/validators'
export default {
  name: 'FormTextAreaLimited',
  props: {
    value: {
      type: String
    },
    label: {
      type: String,
      default: 'Enter a value'
    },
    limit: {
      type: Number,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: 'Please enter a value'
    },
    submitted: {
      type: Boolean,
      default: false
    },
    rows: {
      type: Number,
      default: 3
    }
  },
  mounted () {},
  validations () {
    return {
      value: {
        requiredIf: requiredIf(function () {
          return this.required
        }),
        maxLength: maxLength(this.limit)
      }
    }
  },
  data () {
    return { }
  },
  computed: {
    symbolsLeft () {
      return this.value ? this.limit - Number(this.value.length) : this.limit
    },
    error () {
      return !this.$v.value.requiredIf && this.submitted
    },
    inputText: {
      get () {
        return this.value
      },
      set (inputText) { this.$emit('input', inputText) }
    }
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
  .max-length {
    font-size: 80%;
  }
</style>
