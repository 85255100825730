<template>
  <div class="user-post">
    <h3 class="text-center">Seller wants an offer</h3>
    <h4 class="p-2 pl-3 pr-3">Property description</h4>
    <p class="p-2 pl-3 pr-3">{{ post.property.description }}</p>
    <div>
      <div class="listing-details">
        <div v-for="(item, index) in post.property.paramsAll" :key="index.name" class="d-flex justify-content-between border-bottom">
          <div class="d-flex">
            <CustomIcon :name="item.name" />
            <div class="text ml-2">{{ item.title }}</div>
          </div>
          <div><b>{{ item.value }}</b></div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center mb-4 mt-4">
      <ListingItemImages class="w-60" :listing="post.property" />
    </div>
    <hr class="m-0"/>
    <div class="d-flex flex-wrap justify-content-center mt-3 pl-3 pr-3">
      <button aria-label="Message" type="submit" class="btn btn-homeiz w-60">
        <i class="ri-send-plane-line"></i> Message
      </button>
      <div class="d-flex w-60 mt-3">
        <CashOffer :listing="post.property" />
      </div>
      <div class="d-flex w-60 mt-3">
        <OfferWithLoan :listing="post.property" />
      </div>
    </div>
    <div class="text-center text-blue text-bold mt-2 mb-2">
      <img src="@/assets/images/icon/who-viewed.svg" class="mr-1 menu-icon svg-20"/>
      12 Watchers
    </div>
    <div class="d-flex align-items-center justify-content-center text-small">
      <span>Seller assumes all responsibility for this listing</span>
      <i class="ri-information-line ml-1 cursor-pointer" id="seller"></i>
      <b-tooltip target="seller" custom-class="reserve-tooltip" triggers="hover">
        Responsibility for the listing means that the seller is responsible for accuracy of the description.
      </b-tooltip>
    </div>
    <hr class="m-0 mt-3"/>
  </div>
</template>

<script>
import ListingItemImages from '@/components/homeiz/realEstate/item/ListingItemImages'
import CashOffer from '@/components/homeiz/realEstate/cashOffer/CashOffer'
import OfferWithLoan from '@/components/homeiz/realEstate/offerWithLoan/OfferWithLoan'
import CustomIcon from '@/components/homeiz/common/CustomIcon'

export default {
  name: 'PropertyContent',
  components: { ListingItemImages, CashOffer, OfferWithLoan, CustomIcon },
  props: {
    post: {
      type: Object,
      required: true
    }
  },
  computed: {
    peepVotes () {
      return this.post.pollOptions.reduce((accum, choise) => {
        return accum + choise.votes
      }, 0)
    }
  },
  methods: {
    getPercents (votes) {
      return this.peepVotes ? votes / this.peepVotes * 100 : 0
    },
    addVote (choise) {
      choise.votes++
    }
  }
}
</script>
