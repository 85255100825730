<template>
    <div class="w-100">
        <b-button
          v-show="!offerSent"
          class="w-100"
          v-b-modal.offer-with-a-loan
          variant="royal-blue"
        >
        {{ buttonText }}
        </b-button>
        <b-button
          v-show="offerSent"
          class="w-100"
          v-b-modal.retract-offer
          variant="success"
        >
          Review Offer & Retract
        </b-button>
        <b-modal
          id="offer-with-a-loan"
          :title="title"
          size="md450"
          content-class="rounded"
          hide-footer
          no-close-on-esc
          no-close-on-backdrop
          no-fade
        >
          <b-container fluid>
            <b-row class="mb-1 text-center">
              <b-col md="12">
                <keep-alive>
                  <component
                      v-bind:is="steps[currentStep]"
                      :listing="listing"
                      @onUpdate="onUpdate"
                      @nextStep="nextStep"
                      @previousStep="previousStep"
                      :data="form"
                      @postOffer="postOffer"
                  />
                </keep-alive>
              </b-col>
            </b-row>
          </b-container>
          <template #modal-footer>
            <div></div>
          </template>
        </b-modal>
        <b-modal
          id="retract-offer"
          size="md450"
          content-class="rounded"
          hide-footer
          no-close-on-esc
          no-close-on-backdrop
          no-fade
        >
        <template #modal-title>
          <i class="ri-checkbox-circle-fill text-success font-30"></i> {{ retractTitle }}
        </template>
          <b-container fluid>
            <OfferRetract :data="form" :listing="listing" @retractOffer="retractOffer" />
          </b-container>
          <template #modal-footer>
            <div></div>
          </template>
        </b-modal>
    </div>
</template>

<script>
import moment from 'moment'
import OfferRetract from './OfferRetract'
import OfferStep1 from './OfferStep1'
import OfferStep2 from './OfferStep2'

export default {
  name: 'OfferWithLoan',
  components: { OfferRetract },
  props: {
    listing: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      offerSent: false,
      form: {
        amount: null,
        message: null
      },
      currentStep: 0,
      steps: [
        OfferStep1,
        OfferStep2
      ]
    }
  },
  methods: {
    onUpdate (data) {
      this.form = { ...this.form, ...data }
    },
    nextStep (data) {
      if (this.steps.length >= this.currentStep + 2) {
        this.form = { ...this.form, ...data }
        this.currentStep++
      }
    },
    previousStep () {
      if (this.currentStep > 0) {
        this.currentStep--
      }
    },
    postOffer () {
      this.$bvModal.hide('offer-with-a-loan')
      this.currentStep = 0
      this.offerSent = true
    },
    retractOffer () {
      this.$bvModal.hide('retract-offer')
      this.currentStep = 0
      this.offerSent = false
      this.form = {
        amount: null,
        message: null
      }
    }
  },
  computed: {
    title () {
      return `Make an offer with a loan - ${moment(new Date()).format('MM/DD/YYYY')}`
    },
    retractTitle () {
      return `It's sent - ${moment(new Date()).format('MM/DD/YYYY')}`
    },
    buttonText () {
      return this.offerSent ? 'Offer with a loan sent' : 'Make an offer with a loan'
    }
  }
}
</script>
