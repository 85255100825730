<template>
  <div>
    <div class="d-flex flex-row">
      <div class="user-img mr-1 mt-2 d-block">
        <b-img :src="avatar" alt="userimg" class="avatar-35" rounded="circle" fluid/>
      </div>
      <div class="comment-data-block">
        <div class="d-flex">
          <div class="comment-message-wrapper p-2" v-if="!isEditing">
              <h6 class="d-flex">
                <div :id="`popover-${comment.id}`" class="cursor-pointer mr-2">{{ comment.user.fullName }}</div>
                <b-badge v-if="comment.is_post_author" class="border border-primary text-primary" pill variant="none">Author</b-badge>
              </h6>
              <p class="mb-0 comment-message">{{ comment.message }}</p>
              <ReactionPresenter :reactions="comment.reactions">
                {{ comment.likes }}
              </ReactionPresenter>
              <b-popover custom-class="user-info-popover" :target="`popover-${comment.id}`" placement="top" triggers="hover focus">
                <UserPopover :user="comment.user"/>
              </b-popover>
          </div>
          <FeedCommentEditForm v-if="isEditing" @close-edit-form="isEditing = false" :comment="comment" />
          <div class="comment-action-block ml-1 d-flex" v-if="!isEditing">
            <CommentEditDeleteButton @show-edit-form="isEditing = !isEditing" v-if="isEditable" :comment="comment"/>
          </div>
        </div>
        <div class="d-flex flex-wrap align-items-center comment-activity ml-3 mb-2" v-if="!isEditing">
          <b-link href="javascript:void(0);" @click="onDefaultClick()">
            <ReactionBlock @clicked="onReactionClick" :position="{ bottom: '20px' }">
              <span :style="textColor">
                <img src="@/assets/images/icon/like-icon.svg" class="img-fluid svg-18 mb-1"/>
                <!-- {{ activeReactionText }} -->
              </span>
            </ReactionBlock>
          </b-link>
          <b-link href="javascript:void(0);" class="ml-3 mr-4 cursor-pointer" title="Reply">
            <span @click="replyClick(comment.user.username)" @reply-child-click="replyClick(comment.user.username)">
              <img src="@/assets/images/icon/reply-icon.svg" class="img-fluid mirrored svg-18 mb-1"/>
            </span>
          </b-link>
          <span>{{comment.time | formatDate}}</span>
        </div>
        <div v-for="(comment, commentIndex) in comment.replies" :key="comment.id">
          <div class="d-flex align-items-baseline mt-3">
            <img src="@/assets/images/icon/reply-comment-view-icon.svg" class="img-fluid svg-20 mr-2"/>
            <FeedPostComment :comment="comment" :index="commentIndex" :show-form="false" @reply-child-click="replyClick(comment.user.username)"/>
          </div>
        </div>
        <FeedCommentForm :replyTo="comment" :replyName="replyName" :show-form="isFormVisible" />
      </div>
    </div>
  </div>
</template>

<script>
import ReactionBlock from '@/components/homeiz/reactions/ReactionBlock'
import ReactionPresenter from '@/components/homeiz/reactions/ReactionCommentPresenter'
import FeedCommentForm from '@/components/homeiz/feed/comments/FeedCommentForm'
import FeedCommentEditForm from '@/components/homeiz/feed/comments/FeedCommentEditForm'
import FeedPostComment from '@/components/homeiz/feed/comments/FeedPostComment'
import CommentEditDeleteButton from '@/components/homeiz/feed/comments/CommentEditDeleteButton'
import LikeReaction from '@/FackApi/api/Reactions/Like'
import ReactionList from '@/FackApi/api/Reactions/ReactionList'
import UserPopover from '@/components/homeiz/user/UserPopover'
export default {
  name: 'FeedPostComment',
  components: {
    FeedCommentForm,
    FeedPostComment,
    CommentEditDeleteButton,
    FeedCommentEditForm,
    ReactionBlock,
    ReactionPresenter,
    UserPopover
  },
  inject: ['authUser'],
  props: {
    comment: {
      type: Object,
      required: true
    },
    index: {
      type: Number
    },
    showForm: {
      type: Boolean,
      default: true
    }
  },
  mounted () {
  },
  data () {
    return {
      reply: false,
      replyName: '',
      isEditing: false,
      defaultReaction: LikeReaction,
      reactionList: ReactionList,
      defaultAvatar: require('@/assets/images/user/04.jpg')
    }
  },
  computed: {
    likeClass () {
      return isLiked => isLiked ? 'text-primary' : ''
    },
    isFormVisible () {
      return this.showForm && this.reply
    },
    isEditable () {
      return this.comment.user.id === this.authUser.id
    },
    activeReactionText () {
      return this.comment.like_type ? this.reactionList[this.comment.like_type].title : 'Like'
    },
    textColor () {
      const value = this.comment.is_liked ? this.reactionList[this.comment.like_type].text_color : 'rgb(101, 103, 107)'
      return `color: ${value}`
    },
    avatar () {
      return this.comment.user.avatar ? this.comment.user.avatar : this.defaultAvatar
    }
  },
  methods: {
    replyClick (name) {
      this.replyName = `@${name}`
      this.reply = true
      this.$emit('reply-child-click', `@${name}`)
    },
    onDefaultClick () {
      if (this.comment.is_liked) {
        this.unlike(this.reactionList[this.comment.like_type])
      } else {
        this.like(this.defaultReaction)
      }
    },
    onReactionClick (reaction) {
      if (this.comment.is_liked) {
        if (this.comment.like_type === reaction.id) {
          this.unlike(reaction)
        } else {
          this.updateReaction(reaction)
        }
      } else {
        this.like(reaction)
      }
    },
    like (reaction) {
      this.comment.likes += 1
      this.comment.like_type = reaction.id
      this.comment.is_liked = true
      const index = this.comment.reactions.findIndex(x => x.type.id === reaction.id)
      if (index === -1) {
        this.comment.reactions.push({ type: { ...reaction } })
      }
    },
    unlike (reaction) {
      this.comment.likes -= 1
      this.comment.like_type = null
      this.comment.is_liked = false
      // Remove if reactions only from me
      const index = this.comment.reactions.findIndex(x => x.type.id === reaction.id)
      this.comment.reactions.splice(index, 1)
    },
    updateReaction (reaction) {
      // Change type of like
      const index = this.comment.reactions.findIndex(x => x.type.id === reaction.id)
      this.comment.reactions.splice(index, 1)
      this.comment.reactions.push({ type: { ...reaction } })
      this.comment.like_type = reaction.id
    }
  }
}
</script>

<style lang="scss">
  .comment-action-block {
    align-items: center;
    opacity: 0;
    transition: all 0.5s ease-out;

    .btn {
      width: 38px;
      height: 38px;
      border-radius: 38px !important;
      background: var(--iq-homeiz-blue);
      line-height: 1 !important;
      padding: 0 !important;
      color: var(--iq-black) !important;
    }
  }

  .comment-data-block .d-flex:hover > .comment-action-block {
    opacity: 1;
  }
</style>
