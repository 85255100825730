<template>
  <div>
    <span>{{ title }}</span>
    <img :src="image">
  </div>
</template>

<script>
export default {
  name: 'ReactionIcon',
  props: {
    title: {
      type: String,
      required: true
    },
    image: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
