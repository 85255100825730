<template>
  <div class="user-post">
    <p class="p-2 pl-3 pr-3 word-wrap" v-if="post.description">{{ post.description }}</p>
    <ul class="pl-3 pr-3" v-if="post.pollOptions.length">
      <li class="mb-2 cursor-pointer" v-for="(choise, index) in post.pollOptions" :key="index" @click="addVote(choise)">
        <v-progress-linear rounded :value="getPercents(choise.votes)" height="45" :class="getColor(choise.votes)">
          <strong>{{ choise.value }} - {{ Math.ceil(getPercents(choise.votes)) }}%</strong>
        </v-progress-linear>
      </li>
    </ul>
    <div class="pl-3" v-if="post.pollOptions.length">
      {{ peepVotes }} votes -
      <span class="text-danger">
        <vue-countdown :time="pollTime">
          <template slot-scope="props">Time Left：{{ props.days }}d, {{ props.hours }}h, {{ props.minutes }}m,
            {{ props.seconds }}s
          </template>
        </vue-countdown>
      </span>
    </div>
    <PostPhotos :images="post.images" />
  </div>
</template>

<script>
import PostPhotos from '@/components/homeiz/feed/post/PostPhotos'

export default {
  name: 'PollContent',
  components: { PostPhotos },
  props: {
    post: {
      type: Object,
      required: true
    }
  },
  computed: {
    peepVotes () {
      return this.post.pollOptions.reduce((accum, choise) => {
        return accum + choise.votes
      }, 0)
    },
    pollTime () {
      return this.post.pollDuration.days * 24 * 60 * 60 * 1000 + this.post.pollDuration.hours * 60 * 60 * 1000 + this.post.pollDuration.minutes * 60 * 1000
    }
  },
  methods: {
    getPercents (votes) {
      return this.peepVotes ? votes / this.peepVotes * 100 : 0
    },
    addVote (choise) {
      choise.votes++
    },
    isLeader (votes) {
      const results = this.post.pollOptions.map(object => {
        return object.votes
      })
      const max = Math.max(...results)
      return max === votes ? 'is-leader' : ''
    },
    isLast (votes) {
      const results = this.post.pollOptions.map(object => {
        return object.votes
      })
      const min = Math.min(...results)
      return min === votes ? 'is-last' : ''
    },
    getColor (votes) {
      return `${this.isLast(votes)}  ${this.isLeader(votes)}`
    }
  }
}
</script>
