<template>
  <span class="position-relative" @mouseover="showEmoji" @mouseleave="hideEmoji">
    <ReactionHomeiz :show="emoji" @clicked="onReactionClick" :position="position"/>
    <slot></slot>
  </span>
</template>

<script>
import ReactionHomeiz from '@/components/homeiz/reactions/ReactionHomeiz'
export default {
  name: 'ReactionBlock',
  components: { ReactionHomeiz },
  props: {
    position: {
      type: Object
    }
  },
  data () {
    return {
      emoji: false
    }
  },
  methods: {
    showEmoji () {
      this.emoji = true
    },
    hideEmoji () {
      this.emoji = false
    },
    onReactionClick (reaction) {
      this.$emit('clicked', reaction)
    }
  }
}
</script>

<style scoped>

</style>
