<template>
  <ul class="like-emoji" :class="show ? 'open' : ''" @click.stop="" :style="style">
    <li v-for="reaction in reactions" :key="reaction.id" @click="onReactionClicked(reaction)">
      <ReactionContainer :reaction="reaction" />
    </li>
  </ul>
</template>

<script>
import ReactionContainer from './ReactionContainer'
export default {
  name: 'ReactionList',
  components: {
    ReactionContainer
  },
  props: {
    reactions: {
      type: Array,
      required: true
    },
    show: {
      type: Boolean,
      default: false
    },
    position: {
      type: Object,
      default () {
        return {
          bottom: 0,
          left: 0
        }
      }
    }
  },
  methods: {
    onReactionClicked (reaction) {
      this.$emit('clicked', reaction)
    }
  },
  computed: {
    style () {
      return {
        'bottom': this.position.bottom,
        'left': this.position.left
      }
    }
  }
}
</script>

<style lang="scss">

.like-emoji {
  display: flex;
  list-style: 'none';
  padding: 0 10px;
  background: var(--iq-white);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .08), 0 2px 2px rgba(0, 0, 0, .15);
  border-radius: 30px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s 0.2s;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 9999;

  &.open {
    visibility: visible;
    opacity: 1;

    li {
      transform: translateY(0px) scale(0.8);
    }
  }

  li {
    position: relative;
    display: inline-block;
    cursor: pointer;
    padding: 6px 1px 0px;
    transition: transform 0.2s cubic-bezier(0.76, 0.26, 0.28, 1.4) 0.2s, opacity 0.1s ease 0.2s;
    transform: translateY(30px) scale(0.8);

    &:hover {
      span {
        top: -14px;
        opacity: 1;
        transform: translateY(-6px);
      }
    }

    span {
      background: rgba(0, 0, 0, 0.8) none repeat scroll 0% 0%;
      color: white;
      padding: 0px 6px;
      font-size: 16px;
      border-radius: 12px;
      position: absolute;
      top: -10px;
      left: calc(50% - 23px);
      text-align: center;
      opacity: 0;
      transform: translateY(0px);
      transition: all 0.1s ease 0s;
    }

    &::marker {
      display: none;
    }

    img {
      transition: all 0.2s ease 0s;
      transform: translateY(0px) scale(1);
      transform-origin: center bottom 0px;
      width: 40px;
      margin: 2px;
    }
  }
}

</style>
