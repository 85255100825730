<template>
  <div class="d-flex align-items-center">
    <div class="media-support-user-img mr-2">
      <b-img rounded="circle" class="avatar-50" fluid :src="avatar" :alt="user.fullName" />
    </div>
    <div class="media-support-info">
      <h6 class="mb-0 d-flex align-items-center">
        <b-link :id="`popover-${post.id}`" :to="{ name: 'social.profiles.view', params: { id: user.id } }"
          class="user-link">
          {{ user.fullName }}
        </b-link>
        <span class="user-location d-flex align-items-center">
          <div v-if="user.isBusinessOwner" title="MeTwitt has confirmed the account is validated as business" class="ml-1">
            <img src="@/assets/images/icon/business-owner.svg" class="img-fluid svg-20" />
          </div>
          <div v-else title="MeTwitt has confirmed the account is validated as individual" class="ml-1">
            <img src="@/assets/images/icon/individual-account.svg" class="img-fluid svg-20" />
          </div>
          <p class="mb-0 font-14">
            <span class="font-12">@</span>{{ user.username }}
            <span> · </span>
             {{ post.time | formatDate }}
            <!-- <span> · </span>
            <i class="ri-global-fill" title="Public"></i> -->
          </p>
          <b-button :class="followClass" class="p-0 ml-1 font-10-pt" size="sm" variant="text" @click="followHandler">
            {{ followText }}
          </b-button>
        </span>
        <component
          v-bind:is="notificationButtonComponent"
          :name="post.user.fullName"
        />
      </h6>
      <div class="">
        <GooglePlaceIcon :size="12" />
        Agoura hills, CA 91301
      </div>
      <h6>{{ post.user.role }}</h6>
      <b-popover custom-class="user-info-popover" :target="`popover-${post.id}`" placement="top" triggers="hover focus">
        <component
          v-bind:is="userPopoverComponent"
          :user="post.user"
        />
      </b-popover>
    </div>
  </div>
</template>

<script>
import GooglePlaceIcon from '@/components/homeiz/common/GooglePlaceIcon'

export default {
  name: 'PostUserInfo',
  components: {
    GooglePlaceIcon
  },
  props: {
    post: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: true
    },
    userPopoverComponent: {
      type: Object,
      required: true
    },
    notificationButtonComponent: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      defaultAvatar: require('@/assets/images/user/04.jpg')
    }
  },
  computed: {
    avatar () {
      return this.user.avatar ? this.user.avatar : this.defaultAvatar
    },
    followText () {
      return this.post.is_follow ? 'Following' : 'Follow'
    },
    followClass () {
      return this.post.is_follow ? 'text-black' : ''
    }
  },
  methods: {
    followHandler () {
      this.post.is_follow = !this.post.is_follow
    }
  }
}
</script>
