import LikeReaction from '@/FackApi/api/Reactions/Like'
import LoveReaction from '@/FackApi/api/Reactions/Love'
import CareReaction from '@/FackApi/api/Reactions/Care'
import HahaReaction from '@/FackApi/api/Reactions/Haha'
import WowReaction from '@/FackApi/api/Reactions/Wow'
import SadReaction from '@/FackApi/api/Reactions/Sad'
import AngryReaction from '@/FackApi/api/Reactions/Angry'

export default {
  [LikeReaction.id]: LikeReaction,
  [LoveReaction.id]: LoveReaction,
  [CareReaction.id]: CareReaction,
  [HahaReaction.id]: HahaReaction,
  [WowReaction.id]: WowReaction,
  [SadReaction.id]: SadReaction,
  [AngryReaction.id]: AngryReaction
}
