export default {
  watch: {
    form: {
      handler: function (val, oldVal) {
        this.$emit('onUpdate', this.form)
      },
      deep: true
    }
  },
  methods: {
    previousStep () {
      this.$emit('previousStep', this.form)
    },
    nextStep () {
      try {
        this.$v.$touch()
        this.formSubmitted = true
        if (!this.$v.form.$invalid) {
          this.$emit('nextStep', this.form)
        }
      } catch (e) {

      }
    }
  }
}
