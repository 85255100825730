<template>
  <div class="bell" :class="absolute ? 'position-absolute' : ''" @click="toggleNotification">
    <i v-if="!icon" :class="notificationIcon" :id="`notification-tooltip-${this._uid}`"></i>
    <img v-else :class="status ? 'active': ''" :src="renderIco" class="mr-0 peep-icon" :id="`notification-tooltip-${this._uid}`"/>
    <b-tooltip :target="`notification-tooltip-${this._uid}`" custom-class="reserve-tooltip" placement="left" triggers="hover">
      {{ tooltipText }}
    </b-tooltip>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import * as systemNotificationActions from '@/store/modules/system-notification/types/actions'

export default {
  name: 'NotificationButton',
  props: {
    tooltip: {
      type: String,
      required: true
    },
    tooltipUnsubscribe: {
      type: String,
      default: 'Unsubscribe'
    },
    subscribe: {
      type: String,
      default: 'Subscribed'
    },
    unsubscribe: {
      type: String,
      default: 'Unsubscribed'
    },
    absolute: {
      type: Boolean,
      default: true
    },
    icon: {
      type: String,
      default: null
    },
    iconActive: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      status: false
    }
  },
  computed: {
    notificationIcon () {
      return this.status ? 'ri-notification-2-fill' : 'ri-notification-2-line'
    },
    renderIco () {
      return this.status ? require(`@/assets/images${this.iconActive}`) : require(`@/assets/images${this.icon}`)
    },
    tooltipText () {
      return this.status ? this.tooltipUnsubscribe : this.tooltip
    }
  },
  methods: {
    ...mapActions('systemNotification', {
      setSuccessNotification: systemNotificationActions.SET_SUCCESS_NOTIFICATION
    }),
    toggleNotification () {
      this.status = !this.status
      this.status ? this.setSuccessNotification(this.subscribe) : this.setSuccessNotification(this.unsubscribe)
    }
  }
}
</script>

<style lang="scss" scoped>

 .bell {
    right: 20px;
    font-size: 20px;
    font-weight: 100;
    top: 6px;
    z-index: 99;
    cursor: pointer;

    .active {
      filter: invert(11%) sepia(95%) saturate(7011%) hue-rotate(7deg) brightness(102%) contrast(118%);
    }
  }
</style>
