<template>
  <div class="comment-area pb-2">

    <div class="d-flex justify-content-between align-items-center pl-3 pr-3 pt-3 pb-1">

    <div class="total-like-block action-comment-block">
        <div class="d-flex">
          <ReactionPresenter :reactions="post.reactions"/>
          <div class="dropdown">
            <span class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" role="button">
              <span>
                <span class="ml-1 text-erez-002">
                  {{ likesText }}
                </span>
              </span>
            </span>
            <div class="dropdown-menu">
              <a class="dropdown-item" href="javascript:void(0)">Max Emum</a>
              <a class="dropdown-item" href="javascript:void(0)">Bill Yerds</a>
              <a class="dropdown-item" href="javascript:void(0)">Hap E. Birthday</a>
              <a class="dropdown-item" href="javascript:void(0)">Tara Misu</a>
              <a class="dropdown-item" href="javascript:void(0)">Midge Itz</a>
              <a class="dropdown-item" href="javascript:void(0)">Sal Vidge</a>
              <a class="dropdown-item" href="javascript:void(0)">Other</a>
            </div>
          </div>
        </div>
      </div>

      <!-- <div v-if="fakeNews" class="total-comment-block action-comment-block">
        <div class="dropdown">
          <span class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" role="button">
            <span> {{ post.fake }} Fake news</span>
          </span>
          <div class="dropdown-menu">
            <a class="dropdown-item" href="javascript:void(0)">Max Emum</a>
            <a class="dropdown-item" href="javascript:void(0)">Bill Yerds</a>
            <a class="dropdown-item" href="javascript:void(0)">Hap E. Birthday</a>
            <a class="dropdown-item" href="javascript:void(0)">Tara Misu</a>
            <a class="dropdown-item" href="javascript:void(0)">Midge Itz</a>
            <a class="dropdown-item" href="javascript:void(0)">Sal Vidge</a>
            <a class="dropdown-item" href="javascript:void(0)">Other</a>
          </div>
        </div>
      </div> -->

      <!-- <div class="total-comment-block action-comment-block">
        <div class="dropdown">
          <span class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" role="button">
            <span> 69 Views</span>
          </span>
          <div class="dropdown-menu">
            <a class="dropdown-item" href="javascript:void(0)">Max Emum</a>
            <a class="dropdown-item" href="javascript:void(0)">Bill Yerds</a>
            <a class="dropdown-item" href="javascript:void(0)">Hap E. Birthday</a>
            <a class="dropdown-item" href="javascript:void(0)">Tara Misu</a>
            <a class="dropdown-item" href="javascript:void(0)">Midge Itz</a>
            <a class="dropdown-item" href="javascript:void(0)">Sal Vidge</a>
            <a class="dropdown-item" href="javascript:void(0)">Other</a>
          </div>
        </div>
      </div> -->

      <!-- <div @click="toggleComments" class="total-comment-block action-comment-block">
        <span class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" role="button">
          <span>{{post.comments.length}} Comments</span>
        </span>
      </div> -->

      <!-- <div class="total-comment-block action-comment-block">
        <div class="dropdown">
          <span class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" role="button">
            <span> 99 Share</span>
          </span>
          <div class="dropdown-menu">
            <a class="dropdown-item" href="javascript:void(0)">Max Emum</a>
            <a class="dropdown-item" href="javascript:void(0)">Bill Yerds</a>
            <a class="dropdown-item" href="javascript:void(0)">Hap E. Birthday</a>
            <a class="dropdown-item" href="javascript:void(0)">Tara Misu</a>
            <a class="dropdown-item" href="javascript:void(0)">Midge Itz</a>
            <a class="dropdown-item" href="javascript:void(0)">Sal Vidge</a>
            <a class="dropdown-item" href="javascript:void(0)">Other</a>
          </div>
        </div>
      </div> -->

      <div class="total-comment-block action-comment-block">
        <span class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" role="button">
          <div class="d-flex align-items-center" title="Views">
            <img src="@/assets/images/icon/views.svg" class="img-fluid svg"/>
            <span class="ml-1 text-erez-002">69</span>
          </div>
        </span>
      </div>
    </div>

    <FeedPostActions :fake-news="fakeNews" :repeep="repeep" :post="post"/>
    <hr v-if="!hideComments" class="mt-2 mb-2">

    <div v-if="showComments && !hideComments">
      <div class="d-flex justify-content-between pl-3 pr-3">
        <div class="mb-2 view-more-comments">View 4 more comments</div>
        <div>
          <img src="@/assets/images/icon/sort-by.svg" alt="Sort by" class="img-fluid svg mr-1 cursor-pointer"/>
          <b-dropdown
            :id="`sort-comment-${post.id}`"
            variant="transparent-flat"
            :text="`Sort by: ${sort}`"
            class="sort-filter"
            menu-class="primary-inverted"
          >
            <b-dropdown-item active-class="active" @click="setSort('Newest', $event)">Newest</b-dropdown-item>
            <b-dropdown-item active-class="active" @click="setSort('Oldest',  $event)">Oldest</b-dropdown-item>
            <b-dropdown-item active-class="active" @click="setSort('Top',  $event)">Top</b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <ul class="post-comments pr-3 pl-3 m-0">
        <li class="mb-4" v-for="(comment, commentIndex) in post.comments" :key="comment.id">
          <FeedPostComment @reply-child-click="replyClick" :comment="comment" :index="commentIndex" :show-form="true"/>
        </li>
      </ul>
      <FeedCommentForm class="p-3" :post="post" />
    </div>
  </div>
</template>

<script>
import FeedPostActions from '@/components/homeiz/feed/FeedPostActions'
import ReactionPresenter from '@/components/homeiz/reactions/ReactionPresenter'
import FeedPostComment from '@/components/homeiz/feed/comments/FeedPostComment'
import FeedCommentForm from '@/components/homeiz/feed/comments/FeedCommentForm'
export default {
  name: 'FeedPostComments',
  components: {
    ReactionPresenter,
    FeedPostActions,
    FeedCommentForm,
    FeedPostComment
  },
  props: {
    post: {
      type: Object,
      required: true
    },
    fakeNews: {
      type: Boolean,
      default: false
    },
    repeep: {
      type: Boolean,
      default: false
    },
    hideComments: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      defaultAvatar: require('@/assets/images/user/04.jpg'),
      showComments: true,
      sort: 'Newest'
    }
  },
  methods: {
    isFollowed (follow) {
      this.post.is_follow = follow
    },
    toggleComments () {
      this.showComments = !this.showComments
    },
    replyClick (name) {
      this.replyName = name
      this.reply = true
    },
    setSort (text, event) {
      this.sort = text
    }
  },
  computed: {
    likesCount () {
      return this.post.is_liked ? this.post.likes - 1 : this.post.likes
    },
    likesText () {
      if (this.likesCount > 0) {
        return this.post.is_liked ? `You and ${this.likesCount} others` : this.likesCount
      }

      return this.post.is_liked ? 'You' : this.likesCount
    }
  }
}
</script>

<style scoped>

</style>
