import { render, staticRenderFns } from "./UserInfoStyle2.vue?vue&type=template&id=11320a82&"
import script from "./UserInfoStyle2.vue?vue&type=script&lang=js&"
export * from "./UserInfoStyle2.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports