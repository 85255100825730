<template>
  <div>
    <b-form-group>
      <label v-if="showLabel">$ {{ label }} <span v-if="required">*</span></label>
      <VueNumber class="form-control" v-model="inputText" :class="error ? 'is-invalid' : ''" />
      <div class="d-flex justify-content-between">
        <div class="invalid-feedback d-block" v-if="!$v.value.requiredIf && submitted">
          {{ errorMessage }}
        </div>
      </div>
      </b-form-group>
    </div>
</template>

<script>
import { requiredIf } from 'vuelidate/lib/validators'
export default {
  name: 'FormInputCurrency',
  props: {
    value: {
      type: String
    },
    label: {
      type: String,
      default: 'Enter an amount'
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    required: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: 'Please enter an amount'
    },
    submitted: {
      type: Boolean,
      default: false
    }
  },
  mounted () {},
  validations () {
    return {
      value: {
        requiredIf: requiredIf(function () {
          return this.required
        })
      }
    }
  },
  data () {
    return { }
  },
  computed: {
    error () {
      return (!this.$v.value.requiredIf) && this.submitted
    },
    inputText: {
      get () {
        return this.value
      },
      set (inputText) { this.$emit('input', inputText) }
    }
  }
}
</script>
