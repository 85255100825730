<template>
  <div>
    <div
      class="w-100 cursor-pointer text-hover-underline"
      @click="showDetails"
    >
      6 following
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import * as userActions from '@/store/modules/user-followers/types/actions'

export default {
  name: 'UserFollowing',
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  methods: {
    ...mapActions('userFollowers', {
      setUser: userActions.SET_USER,
      setActiveTab: userActions.SET_ACTIVE_TAB
    }),
    async showDetails () {
      await this.setActiveTab(2)
      await this.setUser(this.user)
      this.$bvModal.show(`user-followers-details`)
      this.$root.$emit('bv::hide::popover')
    }
  }
}
</script>
