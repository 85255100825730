<template>
  <ReactionList :show="show" :reactions="reactions" :position="position" @clicked="onReactionClicked"/>
</template>

<script>
import LikeReaction from '@/FackApi/api/Reactions/Like'
import LoveReaction from '@/FackApi/api/Reactions/Love'
import CareReaction from '@/FackApi/api/Reactions/Care'
import HahaReaction from '@/FackApi/api/Reactions/Haha'
import WowReaction from '@/FackApi/api/Reactions/Wow'
import SadReaction from '@/FackApi/api/Reactions/Sad'
import AngryReaction from '@/FackApi/api/Reactions/Angry'
import ReactionList from '@/components/homeiz/reactions/ReactionList'
export default {
  name: 'ReactionHomeiz',
  components: { ReactionList },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    position: {
      type: Object,
      default () {
        return {
          bottom: 0,
          left: 0
        }
      }
    }
  },
  data () {
    return {
      reactions: [
        LikeReaction,
        LoveReaction,
        CareReaction,
        HahaReaction,
        WowReaction,
        SadReaction,
        AngryReaction
      ]
    }
  },
  methods: {
    onReactionClicked (reaction) {
      this.$emit('clicked', reaction)
    }
  }
}
</script>

<style scoped>

</style>
