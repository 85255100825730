<template>
  <div>
    <i class="custom-icon" v-if="iconClass"></i>
    <template v-else v-html="name">
      <div class="ico">
        <img class="" :src="renderIco(name)"/>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'CustomIcon',
  props: {
    iconClass: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: 'default'
    }
  },
  data () {
    return { }
  },
  mounted () {
  },
  computed: {
    iconsMap () {
      return [
        { name: 'type', file: 'type-icon.svg' },
        { name: 'type-home', file: 'default.svg' },
        { name: 'sqft', file: 'square-foot-icon.svg' },
        { name: 'lot-size', file: 'lot-size-icon.svg' },
        { name: 'price-per-sqft', file: 'price-per-sqft.svg' },
        { name: 'year-built', file: 'year-built-icon.svg' },
        { name: 'beds', file: 'bedrooms-icon.svg' },
        { name: 'bath', file: 'bathrooms-icon.svg' },
        { name: 'parking', file: 'parking-icon.svg' },
        { name: 'air-conditioning-type', file: 'air-conditioning-icon.svg' },
        { name: 'heating-type', file: 'heating-icon.svg' },
        { name: 'swimming-pool', file: 'swimming-pool.svg' },
        { name: 'condition', file: 'condition-icon.svg' },
        { name: 'agency-fee', file: 'agency-fee.svg' },
        { name: 'cap-rate', file: 'cap-rate-icon.svg' },
        { name: 'units', file: 'units-icon.svg' },
        { name: 'ac-lot', file: null },
        { name: 'net', file: null },
        { name: 'rent-control', file: 'rent-control-icon.svg' },
        { name: 'zonning', file: 'zonning-icon.svg' },
        { name: 'noi', file: 'noi-icon.svg' }
      ]
    }
  },
  methods: {
    renderIco (name) {
      const ico = this.iconsMap.find(item => item.name === name)
      return (ico && ico.file) ? require(`@/assets/images/real-estate/icons/${ico.file}`) : require(`@/assets/images/real-estate/icons/default.svg`)
    }
  }
}
</script>

<style lang="scss" scoped>
.ico {
    img {
      width: 19px;
    }
  }
</style>
