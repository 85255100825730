<template>
  <div id="video-grid" class="w-100" v-if="videos.length">
    <!-- <div v-for="(file, index) in videos" :key="index"> -->
      <!-- <video
        class="w-100"
        :src="file.src"
        controls
      /> -->
      <video-player class="w-100" :options="videoOptions"/>
    <!-- </div> -->
  </div>
</template>

<script>
import VideoPlayer from '@/components/homeiz/common/VideoPlayer.vue'

export default {
  name: 'PostVideos',
  components: { VideoPlayer },
  props: {
    videos: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      videoOptions: {
        autoplay: false,
        controls: true,
        fluid: true,
        aspectRation: '16:9'
        // sources: [
        //   {
        //     src: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
        //     type: 'video/mp4'
        //   }
        // ]
      }
    }
  }
}
</script>
