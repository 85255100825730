<template>
    <div class="w-100">
        <b-button
          v-show="!cashOfferSent"
          class="w-100"
          v-b-modal.cash-offer
          variant="success"
        >
        {{ buttonText }}
        </b-button>
        <b-button
          v-show="cashOfferSent"
          class="w-100"
          v-b-modal.retract-cash-offer
          variant="success"
        >
          Review Offer & Retract
        </b-button>
        <b-modal
          id="cash-offer"
          :title="title"
          size="md450"
          content-class="rounded"
          hide-footer
          no-close-on-esc
          no-close-on-backdrop
          no-fade
        >
          <b-container fluid>
            <b-row class="mb-1 text-center">
              <b-col md="12">
                <keep-alive>
                  <component
                      v-bind:is="steps[currentStep]"
                      :listing="listing"
                      @onUpdate="onUpdate"
                      @nextStep="nextStep"
                      @previousStep="previousStep"
                      :data="form"
                      @postOffer="postOffer"
                  />
                </keep-alive>
              </b-col>
            </b-row>
          </b-container>
          <template #modal-footer>
            <div></div>
          </template>
        </b-modal>
        <b-modal
          id="retract-cash-offer"
          size="md450"
          content-class="rounded"
          hide-footer
          no-close-on-esc
          no-close-on-backdrop
          no-fade
        >
        <template #modal-title>
          <i class="ri-checkbox-circle-fill text-success font-30"></i> {{ retractTitle }}
        </template>
          <b-container fluid>
            <CashOfferRetract :data="form" :listing="listing" @retractOffer="retractOffer" />
          </b-container>
          <template #modal-footer>
            <div></div>
          </template>
        </b-modal>
    </div>
</template>

<script>
import moment from 'moment'
import CashOfferRetract from './CashOfferRetract'
import CashOfferStep1 from './CashOfferStep1'
import CashOfferStep2 from './CashOfferStep2'

export default {
  name: 'CashOffer',
  components: { CashOfferRetract },
  props: {
    listing: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      cashOfferSent: false,
      form: {
        amount: null,
        message: null
      },
      currentStep: 0,
      steps: [
        CashOfferStep1,
        CashOfferStep2
      ]
    }
  },
  methods: {
    onUpdate (data) {
      this.form = { ...this.form, ...data }
    },
    nextStep (data) {
      if (this.steps.length >= this.currentStep + 2) {
        this.form = { ...this.form, ...data }
        this.currentStep++
      }
    },
    previousStep () {
      if (this.currentStep > 0) {
        this.currentStep--
      }
    },
    postOffer () {
      this.$bvModal.hide('cash-offer')
      this.currentStep = 0
      this.cashOfferSent = true
    },
    retractOffer () {
      this.$bvModal.hide('retract-cash-offer')
      this.currentStep = 0
      this.cashOfferSent = false
      this.form = {
        amount: null,
        message: null
      }
    }
  },
  computed: {
    title () {
      return `Make an instant cash offer - ${moment(new Date()).format('MM/DD/YYYY')}`
    },
    retractTitle () {
      return `It's sent - ${moment(new Date()).format('MM/DD/YYYY')}`
    },
    buttonText () {
      return this.cashOfferSent ? 'Cash offer sent' : 'Make an instant cash offer'
    }
  }
}
</script>
