<template>
  <div id="photo-grid" v-if="images.length">
    <post-photos-viewer :images="postImages" />
  </div>
</template>

<script>
import PostPhotosViewer from './PostPhotosViewer.vue'
export default {
  components: { PostPhotosViewer },
  name: 'PostPhotos',
  props: {
    images: {
      type: Array,
      required: true
    }
  },
  computed: {
    postImages () {
      if (this.images.some(value => { return typeof value === 'object' })) {
        return this.images.map(image => {
          return image.preview
        })
      } else {
        return this.images
      }
    }
  }
}
</script>
