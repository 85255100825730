
<template>
  <div>
      <div id="drop-area" class="cursor-pointer">
        <form class="images-form">
          <ul v-if="files.length" class="images-preview">
            <li v-for="(file, index) in files" :key="index" :class="file.preview ? '' : 'doc-file'">
              <div class="position-relative" :class="file.preview ? 'd-flex' : 'doc-file'">
                <img v-if="file.preview" :src="file.preview" class="img-fluid rounded preview" :alt="file.name">
                <div v-else >{{ file.name }}</div>
                <div class="remove-image" @click.stop="removeFile(index, $event)">
                  <i class="ri-close-line"></i>
                </div>
              </div>
            </li>
            <li>
              <div class="drag-drop-small" @dragover.prevent @drop.prevent>
                <label :for="`file-${this._uid}`" v-if="files.length < limit" @drop="dragFile" class="drag-area cursor-pointer">
                  <div>
                    <i class="ri-add-line"></i>
                    <div>
                      Add file
                    </div>
                  </div>
                </label>
              </div>
            </li>
          </ul>

          <div class="drag-drop" @dragover.prevent @drop.prevent>
            <label v-if="!files.length" :for="`file-${this._uid}`" @drop="dragFile" class="drag-area cursor-pointer">
              <div>
                <i class="ri-file-add-fill"></i>
                <div class="main-title">{{ title }}</div>
                <div class="description">{{ subTitle }}</div>
                <div>{{ description }}</div>
              </div>
            </label>
            <input
              :id="`file-${this._uid}`"
              v-show="false"
              type="file"
              :accept="accept"
              multiple
              :max="limit"
              @change="uploadFile"
            />
          </div>
        </form>
      </div>
  </div>
</template>

<script>
import { fileSize } from '@/validators/filesize'
import { MAX_FILE_SIZE } from '@/config/file'

export default {
  name: 'FileUploader',
  validations: {
    file: {
      fileSize: fileSize({ maxFileSizeKb: MAX_FILE_SIZE })
    }
  },
  props: {
    title: {
      type: String,
      default: 'Drag and drop files here'
    },
    subTitle: {
      type: String,
      default: 'We accept JPG, PNG, TIFF (100mb file size)'
    },
    description: {
      type: String,
      default: '(Up to 30 Files)'
    },
    accept: {
      type: String
    },
    limit: {
      type: Number,
      default: 30
    }
  },
  data () {
    return {
      files: []
    }
  },
  watch: {
    files: {
      handler: function (val) {
        this.$emit('onPhotoUpdate', this.files)
      },
      deep: true
    }
  },
  methods: {
    dragFile (event) {
      event.dataTransfer.files.forEach(file => {
        this.addFile(file)
      })
    },
    uploadFile (event) {
      event.target.files.forEach(file => {
        this.addFile(file)
      })
    },
    removeFile (index) {
      event.preventDefault()
      event.stopPropagation()
      this.files.splice(index, 1)
    },
    addFile (file) {
      if (file.size < MAX_FILE_SIZE) {
        if (file['type'].split('/')[0] === 'image') {
          file.preview = URL.createObjectURL(file)
        }

        if (this.files.length < this.limit) {
          this.files.push(file)
        }
      } else {
        alert('File too big')
      }
    }
  }
}
</script>

<style lang="scss">
  .drag-drop {
    width: 100%;
  }

  .drag-area {

    i {
      font-size: 24px;
    }

    .main-title {
        font-size: 26px !important;
    }

    .description {
        font-size: 14px;
    }

    width: 100%;
    min-height: 20px;
    padding: 60px 20px 100px 20px;
    text-align: center;
    line-height: 32px;
    background-color: rgb(247, 247, 247);
    border: 1px solid rgba(180, 180, 180);
    border-radius: 25px;
  }

  .images-preview {
    list-style: none;
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    padding: 5px;
  }

  .preview {
    max-width: 100px;
    margin: 5px;
  }

  .remove-image {
    cursor: pointer;
    text-align: center;
    border-radius: 50%;
    top: 8px;
    line-height: 14px;
    color: #000;
    height: 18px;
    width: 18px;
    background-color: #fff;
    position: absolute;
    font-size: 14px;
    right: 8px;
    z-index: 10;
  }

  .drag-drop-small {
    .drag-area {
      border: none !important;
    }
  }

  li {
    &.doc-file {
      width: 100%;
    }
  }

  .doc-file {
    min-height: 40px;
    .remove-image {
      top: 3px;
    }
  }
</style>
