<template>
  <div>
    <div class="position-relative">
      <div class="d-flex iq-card-header-toolbar position-absolute user-card-action-btn">
        <b-dropdown id="dropdownMenuButton44" right variant="facebook-transaprent" class="mb-2" menu-class="p-0">
          <template v-slot:button-content>
            <b-link href="javascript:void(0)" class="text-secondary">
              <i class="ml-2 mr-2 ri-more-fill"></i>
            </b-link>
          </template>
          <a href="javascript:void(0)" class="dropdown-item p-3">
            <div class="d-flex align-items-top">
              <div class="icon font-size-20"><i class="ri-user-add-line"></i></div>
              <div class="data ml-2">
                <div>Follow {{ user.fullName }}</div>
              </div>
            </div>
          </a>
          <a href="javascript:void(0)" class="dropdown-item p-3">
            <div class="d-flex align-items-top">
              <div class="icon font-size-20"><i class="ri-user-fill"></i></div>
              <div class="data ml-2">
                <div>Send {{ user.fullName }} an invite</div>
              </div>
            </div>
          </a>
          <a v-if="!user.role" href="javascript:void(0)" class="dropdown-item p-3">
            <div class="d-flex align-items-top">
              <div class="icon font-size-20"><i class="ri-link"></i></div>
              <div class="data ml-2">
                <div>Save {{ user.fullName }} as lead</div>
              </div>
            </div>
          </a>
          <a v-if="user.role" href="javascript:void(0)" class="dropdown-item p-3">
            <div class="d-flex align-items-top">
              <div class="icon font-size-20"><i class="ri-link"></i></div>
              <div class="data ml-2">
                <div>Save {{ user.fullName }} as agent</div>
              </div>
            </div>
          </a>
          <a href="javascript:void(0)" class="dropdown-item p-3">
            <div class="d-flex align-items-top">
              <div class="icon font-size-20"><i class="ri-close-circle-line"></i></div>
              <div class="data ml-2">
                <div>Block {{ user.fullName }}</div>
              </div>
            </div>
          </a>
        </b-dropdown>
      </div>
    </div>
    <div class="user-info-widget mt-2">
      <div class="d-flex justify-content-center">
        <div class="media-support-user-img-lg">
          <b-img rounded="circle" fluid :src="avatar" :alt="user.fullName"/>
        </div>
      </div>
      <div>
        <div class="media-support-info text-center">
          <div class="mb-0">
            <h5>{{ user.fullName }}</h5>
            <div class="user-location text-royal-blue">
              <GooglePlaceIcon :size="12" />
              Agoura hills - ZIP 91301
            </div>
          </div>
          <div class="text-royal-blue"><b>{{ user.role }}</b></div>
        </div>
      </div>
      <div class="d-flex justify-content-between mt-2">
        <button aria-label="Message" type="submit" class="btn btn-facebook mb-2 ml-1 w-50">
          Business
        </button>
        <button type="submit" class="btn btn-homeiz mb-2 ml-1 w-50">
          Add Friend
        </button>
      </div>
      <div class="d-flex justify-content-between mt-1">
        <div class="w-50 ml-1 text-center">10 mutual connections</div>
        <div class="w-50 ml-1 text-center">7 mutual friends</div>
      </div>
      <div class="d-flex mt-3">
        <button aria-label="Message" type="submit" class="btn btn-homeiz mb-2 ml-1 mr-1 w-100">
          <i class="ri-send-plane-line"></i> Message
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import GooglePlaceIcon from '../common/GooglePlaceIcon'

export default {
  name: 'UserPopover',
  components: {
    GooglePlaceIcon
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      defaultAvatar: require('@/assets/images/user/04.jpg')
    }
  },
  computed: {
    avatar () {
      return this.user.avatar ? this.user.avatar : this.defaultAvatar
    }
  }
}
</script>

<style>
.user-card-action-btn {
    right: 0;
    top: 0;
}

.popover {
  min-width: 400px;
  background: var(--iq-white);
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  border: none;
}
.user-info-widget {
  min-width: 320px;
}
</style>
