<template>
  <div class="event-post position-relative">
    <div class="offer-image d-flex justify-content-center">
      <img :src="listing.image" :alt="listing.title" class="img-fluid rounded">
    </div>
    <div class="iq-card-body text-center p-2">
      <h5>
        {{ listing.address }}, {{ listing.city }}, {{ listing.state }} {{ listing.zip }}
      </h5>
      <div class="d-flex justify-content-around">
        <div v-for="(param, index) in listing.params" :key="index">
          <CustomIcon :name="param.name" /> {{ param.value }}
        </div>
      </div>
      <h3 v-if="listing.price">{{ $n(listing.price, `currencyNoCents`) }}</h3>
    </div>
  </div>
</template>

<script>
import CustomIcon from '@/components/homeiz/common/CustomIcon'

export default {
  name: 'ListingInfo',
  components: {
    CustomIcon
  },
  props: {
    listing: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss">
.offer-image {
    img {
      max-width: 260px;
    }
  }
</style>
