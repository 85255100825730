<template>
    <ReactionIcon :title="reaction.title" :image="reaction.image"/>
</template>

<script>
import ReactionIcon from './ReactionIcon'
export default {
  name: 'ReactionContainer',
  components: { ReactionIcon },
  props: {
    reaction: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
