<template>
  <FormInputCurrency
    v-model="inputText"
    :label="label"
    :required="required"
    :error-message="errorMessage"
    :submitted="submitted"
  />
</template>

<script>
import FormInputCurrency from '@/components/homeiz/common/form/FormInputCurrency'
export default {
  name: 'PropertyDownPaymentAmount',
  components: {
    FormInputCurrency
  },
  props: {
    value: {
      type: [String, Number]
    },
    required: {
      type: Boolean,
      default: false
    },
    submitted: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      label: 'Down Payment Amount',
      errorMessage: 'Please enter a value'
    }
  },
  computed: {
    inputText: {
      get () {
        return this.value
      },
      set (inputText) { this.$emit('input', inputText) }
    }
  }
}
</script>
