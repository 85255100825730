<template>
  <div class="cash-offer">
    <b-row>
      <b-col md="12">
        <h4 class="text-black">Review cash offer</h4>
      </b-col>
      <b-col md="12" class="d-flex justify-content-center currency-input">
        <div class="offer-amount">
          {{ $n(data.amount, `currencyNoCents`) }}
       </div>
      </b-col>
    </b-row>
    <b-row class="d-flex">
      <b-col md="6">
        <UserInfoStyle2 :user="user" />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="mt-2">
        <ListingInfo :listing="listing" />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="text-left">
        <div class="d-flex">
          <div class="mr-2">
            <label><b>Down Payment Amount:</b></label>
          </div>
          <div>
            <i>{{ $n(data.downPaymentAmount, `currencyNoCents`) }}</i>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="text-left">
        <div class="d-flex">
          <div class="mr-2">
            <label><b>Due Deligence/inspection Period:</b></label>
          </div>
          <div>
            <i>{{ data.dueDeligence }} days</i>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="text-left">
        <div class="d-flex">
          <div class="mr-2">
            <label><b>The buyer has to secure a loan:</b></label>
          </div>
          <div>
            <i>{{ data.secureLoan }} days</i>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-2 mb-4" v-if="data.message">
      <b-col md="12" class="text-left"><label><b>Message to seller:</b></label></b-col>
      <b-col md="12" class="text-left">
        <i>{{ data.message }}</i>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <p class="terms-text">By submitting an offer, your are only submitting an initial request to start the buying and selling process, so there are no obligations.</p>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="d-flex justify-content-between">
        <b-button @click="previousStep" variant="facebook">
          Edit Offer
        </b-button>
        <b-button @click="sendOffer" variant="primary">
          Send offer
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import wizardSteps from '@/mixins/wizardSteps'
import { mapActions } from 'vuex'
import * as systemNotificationActions from '@/store/modules/system-notification/types/actions'
import UserInfoStyle2 from '@/components/homeiz/user/UserInfoStyle2'
import ListingInfo from '@/components/homeiz/realEstate/listing/ListingInfo'

export default {
  name: 'OfferStep2',
  components: {
    UserInfoStyle2,
    ListingInfo
  },
  mixins: [wizardSteps],
  inject: ['authUser'],
  props: {
    data: {
      type: Object,
      required: true
    },
    listing: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
    }
  },
  methods: {
    ...mapActions('systemNotification', {
      setDoneNotification: systemNotificationActions.SET_DONE_NOTIFICATION
    }),
    sendOffer () {
      this.setDoneNotification(
        {
          header: 'It\'s sent.',
          text: 'Your offer was successfully sent to the seller. Allow the seller to review your offer. If the seller accepts or declines your offer a notification will be sent to you.'
        }
      )
      this.$emit('postOffer')
    }
  },
  computed: {
    avatar () {
      return this.user.avatar ? this.user.avatar : this.defaultAvatar
    },
    user () {
      return this.authUser
    }
  }
}
</script>

<style lang="scss">
.offer-amount {
  font-size: 36px;
  color: var(--iq-success);
  font-weight: bolder;
}

.cash-offer {
  label {
    color: var(--iq-homeiz-royal-blue) !important;
  }

  .offer-image {
    img {
      max-width: 260px;
    }
  }
}

.terms-text {
  font-size: 11px;
}
</style>
