<template>
  <div class="d-flex" v-if="reactions.length">
    <span v-for="reaction in reactions" :key="reaction.type.id">
      <div class="dropdown">
        <span class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                      role="button">
          <span>
            <img
              :alt="reaction.title"
              class="action-image"
              :src="getReactionImage(reaction.type.id)">
          </span>
        </span>
        <div class="dropdown-menu">
          <div class="dropdown-item no-hover">{{ reaction.type.title }}</div>
          <a class="dropdown-item no-hover" href="javascript:void(0)">Max Emum</a>
          <a class="dropdown-item no-hover" href="javascript:void(0)">Bill Yerds</a>
          <a class="dropdown-item no-hover" href="javascript:void(0)">Hap E. Birthday</a>
          <a class="dropdown-item no-hover" href="javascript:void(0)">Tara Misu</a>
          <a class="dropdown-item no-hover" href="javascript:void(0)">Midge Itz</a>
          <a class="dropdown-item no-hover" href="javascript:void(0)">Sal Vidge</a>
          <a class="dropdown-item no-hover" href="javascript:void(0)">Hap E. Birthday</a>
          <a class="dropdown-item no-hover" href="javascript:void(0)">Tara Misu</a>
          <a class="dropdown-item no-hover" href="javascript:void(0)">Midge Itz</a>
          <a class="dropdown-item no-hover" href="javascript:void(0)">Sal Vidge</a>
          <a class="dropdown-item no-hover" href="javascript:void(0)">Other</a>
        </div>
      </div>
    </span>
  </div>
</template>

<script>
import LikeReaction from '@/FackApi/api/Reactions/Like'
import LoveReaction from '@/FackApi/api/Reactions/Love'
import CareReaction from '@/FackApi/api/Reactions/Care'
import HahaReaction from '@/FackApi/api/Reactions/Haha'
import WowReaction from '@/FackApi/api/Reactions/Wow'
import SadReaction from '@/FackApi/api/Reactions/Sad'
import AngryReaction from '@/FackApi/api/Reactions/Angry'

export default {
  name: 'ReactionPresenter',
  props: {
    reactions: {
      type: Array,
      required: true,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      reactionList: {
        [LikeReaction.id]: LikeReaction,
        [LoveReaction.id]: LoveReaction,
        [CareReaction.id]: CareReaction,
        [HahaReaction.id]: HahaReaction,
        [WowReaction.id]: WowReaction,
        [SadReaction.id]: SadReaction,
        [AngryReaction.id]: AngryReaction
      }
    }
  },
  methods: {
    getReactionImage (id) {
      return this.reactionList[id].icon
    }
  }
}
</script>

<style scoped>
  div.dropdown-item {
    padding: 0.25rem 0 0.75rem 0.5rem;
    color: var(--iq-white);
    font-weight: bolder;
  }
</style>
