<template>
  <div class="listings__image">
    <b-badge class="new-listing-badge" v-if="listing.createdAt" variant="icons">{{ listing.createdAt | formatDate }}</b-badge>
    <b-badge class="photos-badge" variant="secondary">3 Photos</b-badge>
    <div class="image-holder" @click.stop.prevent>
      <swiper
        :pagination="true"
        :navigation="true"
        class="homeiz-swiper"
      >
        <swiper-slide>
          <img :src="listing.image" @click="showListing"/>
        </swiper-slide>
        <swiper-slide>
          <img :src="listing.image" @click="showListing"/>
        </swiper-slide>
        <swiper-slide>
          <img :src="listing.image" @click="showListing"/>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import * as propertyTypes from '@/FackApi/api/RealEstate/Types/propertyTypes'
export default {
  name: 'ListingItemImages',
  props: {
    listing: {
      type: Object,
      required: true
    }
  },
  methods: {
    showListing () {
      const id = this.listing.id
      if (this.listing.type === propertyTypes.VACATION_HOMES_RENTALS) {
        this.$router.push({ name: 'vacation-rentals.listing', params: { id } })
      } else {
        this.$router.push({ name: 'real-estate.listing', params: { id } })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.listings__image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  overflow: hidden;
  position: relative;

  button {
    position: absolute;
    top: 50%;
    width: 32px;
    height: 32px;
    background: white;
    border-radius: 50%;
    overflow: hidden;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    opacity: 0;
    transition: 0.1s ease-in;
    cursor: pointer;
  }

  button:first-of-type {
    left: 10px;
  }

  button:last-of-type {
    right: 10px;
  }

  .new-listing-badge {
    position: absolute;
    top: 4px;
    left: 4px;
    z-index: 9;
  }

  .photos-badge {
    position: absolute;
    top: 4px;
    right: 4px;
    z-index: 10;
  }

  .image-holder {
    display: flex;
  }

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  &:hover {
    button {
      opacity: 0.7;
    }
  }
}
</style>
