<template>
  <div>
    <b-form-group>
      <label>{{ label }} <span v-if="required">*</span></label>
      <b-form-select
        plain
        v-model="selectValue"
        :options="options"
        :state="!error"
        class="cursor-pointer"
        @change="change"
        :disabled="disabled"
      >
        <template v-slot:first>
          <b-form-select-option :value="null" disabled>Select</b-form-select-option>
        </template>
      </b-form-select>
      <div class="d-flex justify-content-between">
        <div class="invalid-feedback d-block" v-if="error">
          {{ errorMessage }}
        </div>
      </div>
      </b-form-group>
    </div>
</template>

<script>
import { requiredIf } from 'vuelidate/lib/validators'
export default {
  name: 'FormSelect',
  props: {
    value: {
      type: [String, Number]
    },
    label: {
      type: String,
      default: 'Enter a value'
    },
    required: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: 'Please enter a value'
    },
    options: {
      type: Array,
      default: () => { return [] }
    },
    submitted: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  mounted () {},
  validations () {
    return {
      value: {
        requiredIf: requiredIf(function () {
          return this.required
        })
      }
    }
  },
  data () {
    return { }
  },
  computed: {
    error () {
      return !this.$v.value.requiredIf && this.submitted
    },
    selectValue: {
      get () {
        return this.value
      },
      set (selectValue) { this.$emit('input', selectValue) }
    }
  },
  methods: {
    change () {
      this.$emit('change')
    }
  }
}
</script>
