<template>
  <div>

    <div class="d-flex flex-wrap justify-content-between align-items-center pl-3 pr-3 mr-0 ml-0 w-100">

      <div class="d-flex justify-content-between w-100">
        <b-button variant="action-transaprent" @click="onDefaultClick()" class="post-action-button" :title="activeReactionText">
          <ReactionBlock @clicked="onReactionClick">
            <div class="d-flex justify-content-center align-items-center" :style="textColor">
              <img v-if="post.is_liked" class="mr-1 active-reaction" :src="activeReactionIcon"/>
              <!-- <div v-else class="icon-bg">
                <img src="@/assets/images/icon/like-icon-black.svg" alt="Comment" class="img-fluid svg-21"/>
              </div> -->
              <img v-else src="@/assets/images/icon/like-icon-new.svg" alt="Like" class="img-fluid svg-21"/>
              <!-- <span class="ml-1">{{ activeReactionText }}</span> -->
              <span class="ml-1 text-bold"> 47.7k</span>
            </div>
          </ReactionBlock>
        </b-button>

        <b-button @click="$bvModal.show(`post-comments-modal-${post.id}`)" variant="action-transaprent" class="post-action-button" title="Comment">
          <div class="d-flex justify-content-center align-items-center">
            <div class="">
              <img src="@/assets/images/icon/comment-icon-black.svg" alt="Comment" class="img-fluid svg-21"/>
            </div>
            <!-- <i class="ri-chat-1-line mr-2"></i> -->
            <!-- <span>Comment</span> -->
            <span class="ml-1"> {{ post.comments.length + 1000}}</span>
          </div>
        </b-button>

        <b-button
          variant="action-transaprent"
          :class="checkBookmark"
          class="post-action-button bookmarks"
          :title="bookmarkTitleText"
          @click="addBookmarkHandler(post)"
        >
          <div class="d-flex justify-content-center align-items-center">
            <div>
              <img :src="renderBookmarkIco" :alt="bookmarkTitleText" class="img-fluid svg-21"/>
            </div>
            <!-- <i class="ri-chat-1-line mr-2"></i> -->
            <!-- <span>Comment</span> -->
            <span class="ml-1">{{ post.bookmark + 1000 }}</span>
          </div>
        </b-button>

        <b-button
          variant="action-transaprent"
          :class="checkFake"
          title="Fake news"
          class="post-action-button fake-news"
          @click="markFake(post)"
        >
          <div class="d-flex justify-content-center align-items-center">
            <div class="">
              <img :src="renderFakeNewsIco" alt="Fake news" class="img-fluid svg-21"/>
            </div>
            <!-- <i class="ri-chat-1-line mr-2"></i> -->
            <!-- <span>Comment</span> -->
            <span class="ml-1">{{ post.fake + 100 }}</span>
          </div>
        </b-button>

        <!-- <b-button
          v-if="repeep"
          variant="action-transaprent"
          :class="checkRetweet"
          class="post-action-button retweet"
          title="Retwitt OLD"
          @click="addRetweetHandler(post)"
        >
          <div class="d-flex justify-content-center align-items-center">
            <div class="">
              <img src="@/assets/images/icon/repeep-icon-black-old265.svg" :alt="retweetTitleText" class="img-fluid svg-21"/>
            </div> -->
            <!-- <i class="ri-alert-line mr-2"></i> -->
            <!-- <span>Comment</span> -->
            <!-- <span class="ml-1">OLD</span>
          </div>
        </b-button> -->

        <b-button
          v-if="repeep"
          variant="action-transaprent"
          :class="checkRetweet"
          class="post-action-button retweet"
          :title="retweetTitleText"
          @click="addRetweetHandler(post)"
        >
          <div class="d-flex justify-content-center align-items-center">
            <div class="">
              <img src="@/assets/images/icon/repeep-icon-black.svg" :alt="retweetTitleText" class="img-fluid svg-21"/>
            </div>
            <!-- <i class="ri-alert-line mr-2"></i> -->
            <!-- <span>Comment</span> -->
            <span class="ml-1">{{ post.retweet + 7232 }}</span>
          </div>
        </b-button>

        <b-dropdown
          id="shareDropDown"
          class="post-action-button"
          right
          variant="action-transaprent"
          menu-class="p-0 share-drop-down"
          no-caret title="Share"
        >
          <template v-slot:button-content>
            <div class="d-flex justify-content-center align-items-center">
              <div class="">
                <img src="@/assets/images/icon/share-icon-black.svg" alt="Share" class="img-fluid svg-21"/>
              </div>
              <!-- <i class="ri-share-forward-line mr-2"></i> -->
              <!-- <span>Share</span> -->
              <span class="ml-1">2276</span>
            </div>
          </template>
          <a href="javascript:void(0)" class="dropdown-item p-2">
            <div class="d-flex align-items-center">
              <div class="icon">
                <img src="@/assets/images/icon/groups.svg" alt="Share" class="img-fluid svg-24"/>
              </div>
              <div class="data ml-2">
                <h6>Share to a group</h6>
              </div>
            </div>
          </a>
          <a href="javascript:void(0)" class="dropdown-item p-2">
            <div class="d-flex align-items-center">
              <div class="icon">
                <img src="@/assets/images/icon/create-company-page.svg" alt="Share" class="img-fluid svg-24"/>
              </div>
              <div class="data ml-2">
                <h6>Share to your company page</h6>
              </div>
            </div>
          </a>
          <a href="javascript:void(0)" class="dropdown-item p-2">
            <div class="d-flex align-items-center">
              <div class="icon">
                <img src="@/assets/images/icon/neighborhood-news-feed.svg" alt="Share" class="img-fluid svg-24"/>
              </div>
              <div class="data ml-2">
                <h6>Share to neigborhood news feed</h6>
              </div>
            </div>
          </a>
          <a href="javascript:void(0)" class="dropdown-item p-2">
            <div class="d-flex align-items-center">
              <div class="icon">
                <img src="@/assets/images/icon/friends-news-feed.svg" alt="Share" class="img-fluid svg-24"/>
              </div>
              <div class="data ml-2">
                <h6>Share to friends news feed</h6>
              </div>
            </div>
          </a>
          <a href="javascript:void(0)" class="dropdown-item p-2">
            <div class="d-flex align-items-center">
              <div class="icon">
                <img src="@/assets/images/icon/parrot-right.svg" alt="Share" class="img-fluid svg-24"/>
              </div>
              <div class="data ml-2">
                <h6>Share to tweet news feed</h6>
              </div>
            </div>
          </a>
          <a href="javascript:void(0)" class="dropdown-item p-2">
            <div class="d-flex align-items-center">
              <div class="icon">
                <img src="@/assets/images/icon/business-news-feed.svg" alt="Share" class="img-fluid svg-24"/>
              </div>
              <div class="data ml-2">
                <h6>Share to business news feed</h6>
              </div>
            </div>
          </a>
          <a href="javascript:void(0)" class="dropdown-item p-2">
            <div class="d-flex align-items-center">
              <div class="icon">
                <img src="@/assets/images/icon/brokerage-news-feed.svg" alt="Share" class="img-fluid svg-24"/>
              </div>
              <div class="data ml-2">
                <h6>Share to brokerage news feed</h6>
              </div>
            </div>
          </a>
          <a href="javascript:void(0)" class="dropdown-item p-2">
            <div class="d-flex align-items-center">
              <div class="icon font-size-24">
                <i class="ri-send-plane-fill"></i>
              </div>
              <div class="data ml-2">
                <h6>Send in Messenger</h6>
              </div>
            </div>
          </a>
          <a href="javascript:void(0)" class="dropdown-item p-2">
            <div class="d-flex align-items-center">
              <div class="icon">
                <img src="@/assets/images/icon/social-media/facebook.svg" alt="Share" class="img-fluid svg-24"/>
              </div>
              <div class="data ml-2">
                <h6>Share to facebook</h6>
              </div>
            </div>
          </a>
          <a href="javascript:void(0)" class="dropdown-item p-2">
            <div class="d-flex align-items-center">
              <div class="icon">
                <img src="@/assets/images/icon/social-media/linkedin.svg" alt="Share" class="img-fluid svg-24"/>
              </div>
              <div class="data ml-2">
                <h6>Share to Linkein</h6>
              </div>
            </div>
          </a>
          <a href="javascript:void(0)" class="dropdown-item p-2">
            <div class="d-flex align-items-center">
              <div class="icon">
                <img src="@/assets/images/icon/social-media/line.svg" alt="Share" class="img-fluid svg-24"/>
              </div>
              <div class="data ml-2">
                <h6>Share to Line</h6>
              </div>
            </div>
          </a>
          <a href="javascript:void(0)" class="dropdown-item p-2">
            <div class="d-flex align-items-center">
              <div class="icon">
                <img src="@/assets/images/icon/social-media/pinterest.svg" alt="Share" class="img-fluid svg-24"/>
              </div>
              <div class="data ml-2">
                <h6>Share to Pinterest</h6>
              </div>
            </div>
          </a>
          <a href="javascript:void(0)" class="dropdown-item p-2">
            <div class="d-flex align-items-center">
              <div class="icon">
                <img src="@/assets/images/icon/social-media/reddit.svg" alt="Share" class="img-fluid svg-24"/>
              </div>
              <div class="data ml-2">
                <h6>Share to Reddit</h6>
              </div>
            </div>
          </a>
          <a href="javascript:void(0)" class="dropdown-item p-2">
            <div class="d-flex align-items-center">
              <div class="icon">
                <img src="@/assets/images/icon/social-media/twitter.svg" alt="Share" class="img-fluid svg-24"/>
              </div>
              <div class="data ml-2">
                <h6>Share to Twitter</h6>
              </div>
            </div>
          </a>
          <a href="javascript:void(0)" class="dropdown-item p-2">
            <div class="d-flex align-items-center">
              <div class="icon">
                <img src="@/assets/images/icon/social-media/whatsapp.svg" alt="Share" class="img-fluid svg-24"/>
              </div>
              <div class="data ml-2">
                <h6>Share to Whatsapp</h6>
              </div>
            </div>
          </a>
          <a href="javascript:void(0)" class="dropdown-item p-2">
            <div class="d-flex align-items-center">
              <div class="icon">
                <img src="@/assets/images/icon/social-media/telegram.svg" alt="Share" class="img-fluid svg-24"/>
              </div>
              <div class="data ml-2">
                <h6>Share to Telegram</h6>
              </div>
            </div>
          </a>
        </b-dropdown>
      </div>

      <!-- <b-button variant="action" class="share-block post-action-button w-100 feather-icon d-none d-md-flex justify-content-center" title="Message">
        <div>
          <i class="ri-send-plane-line mr-2"></i>
          span class="d-none d-md-inline-flex">Message</span>
        </div>
      </b-button> -->
      <b-modal
        :id="`post-comments-modal-${post.id}`"
        size="lg" :title="title"
        content-class="rounded"
        no-fade
        scrollable
      >
        <div>
          <iq-card v-if="!post.is_hidden" body-class="p-0">
              <template v-slot:body>
                  <div class="user-post-data p-3">
                      <div class="d-flex flex-wrap">
                          <div class="media-support-user-img mr-3">
                              <b-img rounded="circle" fluid :src="avatar" :alt="post.user.fullName"/>
                          </div>
                          <div class="media-support-info">
                              <h5 class="mb-0 d-flex align-items-center">
                                    <b-link :id="`popover-comment-${post.id}`" :to="{ name: 'social.profiles.view', params: { id: post.user.id }}" class="user-link">
                                      {{post.user.fullName}}
                                    </b-link>
                                    <span class="user-location d-flex align-items-center ml-2">
                                      <div>- Agoura hills - ZIP 91301</div>
                                    </span>
                              </h5>
                              <h6>{{ post.user.role }}</h6>
                              <p class="mb-0 text-secondary">
                                  {{ post.time | formatDate }}
                                  <span> · </span>
                                  <i class="ri-global-fill" title="Public"></i>
                              </p>
                              <b-popover custom-class="user-info-popover" :target="`popover-comment-${post.id}`" placement="top" triggers="hover focus">
                                <UserPopover :user="post.user"/>
                              </b-popover>
                          </div>
                      </div>
                  </div>
              </template>
              <hr class="m-0"/>
              <div class="user-post">
                  <p class="p-2 pl-3 pr-3" v-if="post.description">{{post.description}}</p>
                  <div id="photo-grid" v-if="post.images.length">
                      <photo-grid :box-height="'400px'" :box-width="'100%'" :boxBorder=2>
                          <img v-for="(image, index) in post.images" :src="image" :key="index"/>
                      </photo-grid>
                  </div>
              </div>
              <div>
                <div class="d-flex justify-content-between pl-3 pr-3">
                  <div class="mb-2 view-more-comments">View 4 more comments</div>
                <div>
                <img src="@/assets/images/icon/sort-by.svg" alt="Sort by" class="img-fluid svg mr-1 cursor-pointer"/>
                <b-dropdown
                  :id="`sort-comment-${post.id}`"
                  variant="transparent-flat"
                  :text="`Sort by: ${sort}`"
                  class="sort-filter"
                  menu-class="primary-inverted"
                >
                  <b-dropdown-item active-class="active" @click="setSort('Newest', $event)">Newest</b-dropdown-item>
                  <b-dropdown-item active-class="active" @click="setSort('Oldest',  $event)">Oldest</b-dropdown-item>
                  <b-dropdown-item active-class="active" @click="setSort('Top',  $event)">Top</b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
            <ul class="post-comments pr-3 pl-3 m-0">
              <li class="mb-2" v-for="(comment, commentIndex) in post.comments" :key="comment.id">
                <FeedPostComment @reply-child-click="replyClick" :comment="comment" :index="commentIndex" :show-form="true"/>
              </li>
            </ul>
            </div>
          </iq-card>
        </div>
        <template #modal-footer="">
          <div class="w-100">
            <FeedCommentForm class="p-3" :post="post" />
          </div>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import * as feedActions from '@/store/modules/feed/types/actions'
import ReactionBlock from '@/components/homeiz/reactions/ReactionBlock'
import LikeReaction from '@/FackApi/api/Reactions/Like'
import ReactionList from '@/FackApi/api/Reactions/ReactionList'
import FeedPostComment from '@/components/homeiz/feed/comments/FeedPostComment'
import FeedCommentForm from '@/components/homeiz/feed/comments/FeedCommentForm'
import UserPopover from '@/components/homeiz/user/UserPopoverPeep'

export default {
  name: 'FeedPostActions',
  components: {
    ReactionBlock,
    FeedPostComment,
    FeedCommentForm,
    UserPopover
  },
  props: {
    post: {
      type: Object,
      required: true
    },
    fakeNews: {
      type: Boolean,
      default: false
    },
    repeep: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      defaultReaction: LikeReaction,
      reactionList: ReactionList,
      fake: false,
      bookmark: false,
      showComments: false,
      retweet: false,
      sort: 'Newest'
    }
  },
  methods: {
    ...mapActions('feed', {
      addBookmark: feedActions.ADD_BOOKMARK,
      removeBookmark: feedActions.REMOVE_BOOKMARK
    }),
    onDefaultClick () {
      if (this.post.is_liked) {
        this.unlike(this.reactionList[this.post.like_type])
      } else {
        this.like(this.defaultReaction)
      }
    },
    onReactionClick (reaction) {
      if (this.post.is_liked) {
        if (this.post.like_type === reaction.id) {
          this.unlike(reaction)
        } else {
          this.updateReaction(reaction)
        }
      } else {
        this.like(reaction)
      }
    },
    like (reaction) {
      this.post.likes += 1
      this.post.like_type = reaction.id
      this.post.is_liked = true
      const index = this.post.reactions.findIndex(x => x.type.id === reaction.id)
      if (index === -1) {
        this.post.reactions.push({ type: { ...reaction } })
      }
    },
    unlike (reaction) {
      this.post.likes -= 1
      this.post.like_type = null
      this.post.is_liked = false
      // Remove if reactions only from me
      const index = this.post.reactions.findIndex(x => x.type.id === reaction.id)
      this.post.reactions.splice(index, 1)
    },
    updateReaction (reaction) {
      // Change type of like
      const index = this.post.reactions.findIndex(x => x.type.id === reaction.id)
      this.post.reactions.splice(index, 1)
      this.post.reactions.push({ type: { ...reaction } })
      this.post.like_type = reaction.id
    },
    markFake () {
      if (this.fake) {
        this.post.fake--
      } else {
        this.post.fake++
      }

      this.fake = !this.fake
    },
    addBookmarkHandler (post) {
      if (this.bookmark) {
        this.removeBookmark(post)
        this.post.bookmark--
      } else {
        this.addBookmark(post)
        this.post.bookmark++
      }
      this.bookmark = !this.bookmark
    },
    addRetweetHandler (post) {
      if (this.retweet) {
        this.post.retweet--
      } else {
        this.post.retweet++
      }

      this.retweet = !this.retweet
    },
    hidePost () {
      this.post.is_hidden = !this.post.is_hidden
    },
    setSort (text, event) {
      this.sort = text
    },
    replyClick (name) {
      this.replyName = name
      this.reply = true
    }
  },
  computed: {
    avatar () {
      return this.post.user.avatar ? this.post.user.avatar : this.defaultAvatar
    },
    textColor () {
      const value = this.post.is_liked ? this.reactionList[this.post.like_type].text_color : 'rgb(101, 103, 107)'
      return `color: ${value}`
    },
    activeReactionIcon () {
      return this.post.like_type ? this.reactionList[this.post.like_type].icon : ''
    },
    activeReactionText () {
      return this.post.like_type ? this.reactionList[this.post.like_type].title : 'Like'
    },
    checkFake () {
      return this.fake ? 'active' : ''
    },
    renderFakeNewsIco () {
      return this.fake ? require('@/assets/images/icon/fake-news-icon-active.svg') : require('@/assets/images/icon/fake-news-icon.svg')
    },
    checkRetweet () {
      return this.retweet ? 'active' : ''
    },
    checkLike () {
      return this.post.is_liked ? 'active' : ''
    },
    likeIcon () {
      return this.post.is_liked ? require('@/assets/images/icon/red-heart-icon.svg') : require('@/assets/images/icon/like-icon-black.svg')
    },
    retweetTitleText () {
      return this.retweet ? 'Undo retweet' : 'Retweet'
    },
    checkBookmark () {
      return this.bookmark ? 'active' : ''
    },
    bookmarkTitleText () {
      return this.bookmark ? 'Remove from Bookmarks' : 'Bookmark'
    },
    renderBookmarkIco () {
      return this.bookmark ? require(`@/assets/images/icon/bookmarks-active.svg`) : require(`@/assets/images/icon/bookmarks-black.svg`)
    },
    likeTitleText () {
      return this.post.is_liked ? 'Unlike' : 'Like'
    },
    title () {
      const name = `${this.post.user.fullName}'s`
      const type = this.post.type === 1 ? 'Post' : 'Tweet'
      return `${name} ${type}`
    }
  }
}
</script>

<style lang="scss">
  .active-reaction {
    width: 27px;
  }
  .post-action-button {
    padding: 6px 0;
    div {
      i {
        font-size: 18px;
        filter: brightness(1.4);
        font-weight: lighter;

        &.ri-share-forward-line {
          font-size: 20px;
        }
      }
    }

    span {
      font-size: 10pt;
      font-family: 'Roboto';
      font-weight: 500;
      color: rgb(140, 140, 140);
    }
  }

  .homeiz-link-active {
    i {
      filter: brightness(1) !important;
    }
    color: var(--iq-primary);
  }

  .social-share {
    padding: 3px;
    width: 28px;
    height: 28px;
  }
</style>
