<template>
  <div class="position-relative">
    <div class="d-flex total-like-block comment-action-presenter" v-if="reactions.length">
      <span v-for="reaction in reactions" :key="reaction.type.id">
        <div class="dropdown position-relative">
          <span class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                role="button">
            <span>
              <img
                :alt="reaction.title"
                class="action-image"
                :src="getReactionImage(reaction.type.id)">
            </span>
          </span>
          <div class="dropdown-menu">
            <div class="dropdown-item no-hover">{{ reaction.type.title }}</div>
            <a class="dropdown-item no-hover" href="javascript:void(0)">Max Emum</a>
            <a class="dropdown-item no-hover" href="javascript:void(0)">Bill Yerds</a>
            <a class="dropdown-item no-hover" href="javascript:void(0)">Hap E. Birthday</a>
            <a class="dropdown-item no-hover" href="javascript:void(0)">Tara Misu</a>
            <a class="dropdown-item no-hover" href="javascript:void(0)">Midge Itz</a>
            <a class="dropdown-item no-hover" href="javascript:void(0)">Sal Vidge</a>
            <a class="dropdown-item no-hover" href="javascript:void(0)">Hap E. Birthday</a>
            <a class="dropdown-item no-hover" href="javascript:void(0)">Tara Misu</a>
            <a class="dropdown-item no-hover" href="javascript:void(0)">Midge Itz</a>
            <a class="dropdown-item no-hover" href="javascript:void(0)">Sal Vidge</a>
            <a class="dropdown-item no-hover" href="javascript:void(0)">Other</a>
          </div>
        </div>
      </span>
      <span class="count">
      <slot></slot>
      </span>
    </div>
  </div>
</template>

<script>
import LikeReaction from '@/FackApi/api/Reactions/Like'
import LoveReaction from '@/FackApi/api/Reactions/Love'
import CareReaction from '@/FackApi/api/Reactions/Care'
import HahaReaction from '@/FackApi/api/Reactions/Haha'
import WowReaction from '@/FackApi/api/Reactions/Wow'
import SadReaction from '@/FackApi/api/Reactions/Sad'
import AngryReaction from '@/FackApi/api/Reactions/Angry'

export default {
  name: 'ReactionPresenter',
  props: {
    reactions: {
      type: Array,
      required: true,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      reactionList: {
        [LikeReaction.id]: LikeReaction,
        [LoveReaction.id]: LoveReaction,
        [CareReaction.id]: CareReaction,
        [HahaReaction.id]: HahaReaction,
        [WowReaction.id]: WowReaction,
        [SadReaction.id]: SadReaction,
        [AngryReaction.id]: AngryReaction
      }
    }
  },
  methods: {
    getReactionImage (id) {
      return this.reactionList[id].icon
    }
  }
}
</script>

<style lang="scss" scoped>

  .dropdown-menu {
    left: -160px !important;
  }
  div.dropdown-item {
    padding: 0.25rem 0 0.75rem 0.5rem;
    color: var(--iq-white);
    font-weight: bolder;
  }

  .comment-action-presenter {
    background: var(--iq-white);
    box-shadow: rgba(0, 0, 0, 0.2) 0 1px 3px 0;
    position: absolute;
    padding: 2px 2px 2px 6px;
    border-radius: 10px;
    right: 8px;
    bottom: -18px;
  }

  .comment-message-wrapper {
    span {
      line-height: 13px;
    }
  }

  .action-image {
    width: 16px !important;
    height: 16px !important;
    margin: 0;
  }

  .count {
    padding: 0 0 0 2px;
    font-size: 12px;
    color: var(--iq-body-text);
  }
</style>
