<template>
  <div class="user-post">
    <div class="p-2 pl-3 pr-3 word-wrap" v-if="post.description">
      <div v-html="post.description"></div>
    </div>
    <PostFiles :files="post.files" />
    <PostVideos :videos="post.videos" />
    <PostPhotos :images="post.images" />
  </div>
</template>

<script>
import PostPhotos from '@/components/homeiz/feed/post/PostPhotos'
import PostFiles from '@/components/homeiz/feed/post/PostFiles'
import PostVideos from '../PostVideos.vue'

export default {
  name: 'PostContent',
  components: {
    PostPhotos,
    PostFiles,
    PostVideos
  },
  props: {
    post: {
      type: Object,
      required: true
    }
  }
}
</script>
