<template>
  <div>
    <b-button
      :aria-label="followText"
      type="submit"
      :variant="!stateLocal ? variant : 'transparent'"
      class="elleptical w-100"
      :class="stateLocal && unhover ? 'follow-btn-following' : ''"
      @click="toggleFollow"
      v-b-hover="hoverHandler"
    >
      <span>{{ followText }}</span>
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'FollowButton',
  props: {
    variant: {
      type: String,
      default: 'erez008'
    },
    value: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      unhover: false
    }
  },
  computed: {
    followText () {
      return this.stateLocal ? 'Following' : 'Follow'
    },
    stateLocal: {
      get: function () {
        return this.value
      },
      set: function (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    toggleFollow () {
      this.stateLocal = !this.stateLocal
      if (!this.stateLocal) {
        this.unhover = false
      }
    },
    hoverHandler (isHovered) {
      if (isHovered) {

      } else {
        if (this.stateLocal === true) {
          this.unhover = true
        }
      }
    }
  }
}
</script>

<style lang="scss">
.follow-btn-following {
  &:hover {
    border-color: red !important;
    background-color: transparent !important;
    color: red !important;

    span {
      display: none;
    }

    &:before {
      content: 'Unfollow';
    }
  }
}
</style>
