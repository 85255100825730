<template>
<div v-if="isVisible">
  <b-form  class="comment-text d-flex align-items-center mt-3">
    <div class="user-img mr-1">
      <b-img :src="avatar" alt="userimg" class="avatar-35" rounded="circle" fluid/>
    </div>
    <b-form-input
      type="text"
      @keyup.enter="saveComment()"
      v-model="commentMessage"
      class="comment-input"
      placeholder="My comment..."
    />
    <div class="comment-attagement d-flex">
      <b-link href="javascript:void(0);">
        <img src="@/assets/images/icon/camera-black.svg" alt="Picture" class="img-fluid svg mr-2" title="GIF"/>
        <!-- <i class="ri-vidicon-line mr-2"></i> -->
      </b-link>
      <b-link href="javascript:void(0);">
        <img src="@/assets/images/icon/comment-gif-black.svg" alt="GIF" class="img-fluid svg mr-2" title="GIF"/>
        <!-- <i class="ri-vidicon-line mr-2"></i> -->
      </b-link>
      <b-link href="javascript:void(0);">
        <img src="@/assets/images/icon/comment-sticker.svg" alt="Sticker" class="img-fluid svg-20 mr-2" title="Sticker"/>
        <!-- <i class="ri-link mr-2"></i> -->
      </b-link>
      <b-link href="javascript:void(0);">
        <img src="@/assets/images/icon/comment-emoji.svg" alt="Emoji" class="img-fluid svg mr-2" title="Emoji"/>
        <!-- <i class="ri-camera-line mr-2"></i> -->
      </b-link>
      <!-- <b-link>
        <i class="ri-emotion-line mr-2"></i>
      </b-link> -->
    </div>
  </b-form>
  <div v-if="inputText !== ''">
    <b-button class="mt-2" variant="primary" @click="saveComment()">{{ buttonTitle }}</b-button>
  </div>
</div>
</template>

<script>
import CommentModel from '@/Model/Comment'
import UserModel from '@/Model/User'

export default {
  name: 'FeedCommentForm',
  inject: ['authUser'],
  props: {
    showForm: {
      type: Boolean,
      default: true
    },
    post: {
      type: Object
    },
    replyTo: {
      type: Object
    },
    replyName: {
      type: String
    }
  },
  mounted () { },
  data () {
    return {
      inputText: ''
    }
  },
  methods: {
    saveComment () {
      this.post.comments.push(new CommentModel(
        {
          image: require('@/assets/images/user/user-04.jpg'),
          user: new UserModel(this.authUser),
          message: this.inputText
        }
      ))

      this.commentMessage = ''
    }
  },
  computed: {
    commentMessage: {
      get () {
        return this.replyName ? `Reply to ${this.replyName} ` : ''
      },
      set (val) {
        this.inputText = val
      }
    },
    isVisible () {
      return this.showForm
    },
    avatar () {
      return this.authUser.avatar
    },
    buttonTitle () {
      return this.replyName ? 'Reply' : 'Post'
    }
  }
}
</script>

<style scoped>

</style>
