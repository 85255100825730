<template>
  <div id="files-grid" class="d-flex justify-content-end" v-if="files.length">
    <div class="w-10 cursor-pointer" v-for="(file, index) in files" :key="index">
      <a href="#">
        <img src="@/assets/images/file/file-uploader.png" :alt="file.name" :title="file.name" class="img-fluid w-100"/>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PostFiles',
  props: {
    files: {
      type: Array,
      required: true
    }
  }
}
</script>
