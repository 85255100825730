<template>
  <ButtonWithTooltip :id="this._uid" tooltip-text="Edit or delete this">
    <template #button-items>
      <b-dropdown-item @click="editComment">Edit</b-dropdown-item>
      <b-dropdown-item @click="deleteComment">Delete</b-dropdown-item>
    </template>
  </ButtonWithTooltip>
</template>

<script>
import ButtonWithTooltip from '@/components/homeiz/common/ButtonWithTooltip'

export default {
  name: 'CommentEditDeleteButton',
  components: { ButtonWithTooltip },
  props: {
    comment: {
      type: Object,
      required: true
    }
  },
  methods: {
    deleteComment () {
      if (confirm('Are sure you want to delete this comment?')) {

      }
    },
    editComment () {
      this.$emit('show-edit-form')
    }
  }
}
</script>

<style scoped>

</style>
