export default {
  created () {
    let that = this

    document.addEventListener('keyup', function (evt) {
      if (evt.keyCode === 81) {
        that.close()
      }
    })
  }
}
