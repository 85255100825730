<template>
  <main>
    <viewer
      class="viewer"
      ref="viewer"
      :options="options"
      :images="images"
      @inited="inited"
    >
      <template #default="scope">
        <div class="d-flex flex-wrap images-canvas">
          <div class="img-holder" :class="imageClass" v-for="(src, index) in scope.images" :key="index">
            <img class="post-image" :src="src" />
            <div v-if="images.length > 5 && index === 4" @click="show">
              +{{ images.length }}
            </div>
          </div>
        </div>
      </template>
    </viewer>
  </main>
</template>

<script>
export default {
  name: 'PostPhotosViewer',
  props: {
    images: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      options: {
        backdrop: true,
        button: true,
        navbar: 2,
        title: 2,
        toolbar: {
          zoomIn: 4,
          zoomOut: 4,
          oneToOne: 4,
          reset: 0,
          prev: 4,
          play: {
            show: 4,
            size: 'large'
          },
          next: 4,
          rotateLeft: 0,
          rotateRight: 0,
          flipHorizontal: 0,
          flipVertical: 0
        }
      }
    }
  },
  computed: {
    imageClass () {
      return this.images.length > 4 ? 'images' : `images-${this.images.length}`
    }
  },
  methods: {
    inited (viewer) {
      this.$viewer = viewer
    },
    show () {
      this.$viewer.show()
    }
  }
}
</script>

<style lang="scss" scoped>
.img-holder {
  overflow: hidden;
  width: 100%;
  background: var(--iq-grey);
  position: relative;

  div {
    cursor: pointer;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 100%;
    width: 100%;
    color: var(--iq-white);
    font-size: 32px;
    background-color: rgba(0, 0, 0, 0.6);
  }

  &:nth-child(n+6) {
    display: none;
  }

  &.images-2 {
    width: 50%;
  }

  &.images-3:nth-child(n+2) {
    width: 50%;
  }

  &.images-4:nth-child(n+2) {
    width: 33.33%;
  }

  &.images {
    width: 50%;

    &:nth-child(n+3) {
      width: 33.33%;
    }
  }
}

// .img-holder:nth-child(3):after {
//   position: absolute;
//   top: 0;
//   bottom: 0;
//   left: 0;
//   right: 0;
//   margin: auto;
//   content: 'View All';
// }

.post-image {
  height: 100%;
  width: 100%;
  cursor: pointer;
  object-fit: cover;
}

.images-canvas {
  border-radius: 25px;
  overflow: hidden;
}
</style>
