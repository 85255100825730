<template>
  <div>
    <b-row class="cash-offer">
      <b-col md="12" class="d-flex justify-content-center currency-input">
        <div>
          <b-form-group>
            <VueNumber class="form-control" v-model="form.amount" :class="!error ? 'is-invalid' : ''" />
            <div class="d-flex justify-content-between w-100">
              <div class="invalid-feedback d-block" v-if="!error">
                Please enter your offer.
              </div>
            </div>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <b-row class="d-flex">
      <b-col md="6">
        <UserInfoStyle2 :user="user" />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="mt-1">
        <ListingInfo :listing="listing" />
      </b-col>
    </b-row>
    <b-row class="main-form text-left mb-2 mt-2">
      <b-col md="12">
        <property-down-payment-amount v-model="form.downPaymentAmount" required :submitted="formSubmitted" />
      </b-col>
    </b-row>
    <b-row class="main-form text-left mb-2 mt-2">
      <b-col md="12">
        <PropertyDueDeligencePeriodSelect v-model="form.dueDeligence" required :submitted="formSubmitted" />
      </b-col>
    </b-row>
    <b-row class="main-form text-left mb-2 mt-2">
      <b-col md="12">
        <property-buyer-secure-loan v-model="form.secureLoan" required :submitted="formSubmitted" />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="mt-4 text-bold preaproval-letter">
        Upload a loan prequalification or preaproval letter
        <img src="@/assets/images/icon/info.svg" alt="Info" id="preaproval-letter" class="img-fluid svg ml-1 cursor-pointer"/>
        <b-tooltip target="preaproval-letter" custom-class="reserve-tooltip wide" triggers="hover">
          <p>
            A prequalification or preapproval letter is a document from a lender stating that the lender
            is tentatively willing to lend to you, up to a certain loan amount.
          </p>
          <p>
            This document is based on certain assumptions and it is not a guaranteed loan offer. But,
            it lets the seller know that you are likely to be able to get financing. Sellers frequently require a
            prequalification or preapproval letter before accepting your offer on a house.
          </p>
        </b-tooltip>
      </b-col>
    </b-row>
    <b-row class="mt-2 mb-2">
      <b-col md="12">
        <FileUploader
          subTitle="We accept PDF, DOC (100mb file size)"
          accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf"
        />
      </b-col>
    </b-row>
    <b-row class="main-form">
      <b-col md="12" class="text-left">
        <FormTextAreaLimited v-model="form.message" :rows="1" label="Message (optional)" :limit="250"/>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <p class="terms-text">By submitting an offer, your are only submitting an initial request to start the buying and selling process, so there are no obligations.</p>
        <p class="terms-text">All offers will expired within 72 hours from the time it was received.</p>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mt-2" md="12">
        <b-button @click="nextStep" :disabled="!$v.form.amount.required" size="lg" variant="primary" class="w-100 review">
          Review Offer
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import wizardSteps from '@/mixins/wizardSteps'
import FileUploader from '@/components/homeiz/common/form/FileUploader'
import FormTextAreaLimited from '@/components/homeiz/common/form/FormTextAreaLimited'
import UserInfoStyle2 from '@/components/homeiz/user/UserInfoStyle2'
import ListingInfo from '@/components/homeiz/realEstate/listing/ListingInfo'
import PropertyDueDeligencePeriodSelect from '../form/PropertyDueDeligencePeriodSelect.vue'
import PropertyBuyerSecureLoan from '../form/PropertyBuyerSecureLoan.vue'
import PropertyDownPaymentAmount from '../form/PropertyDownPaymentAmount.vue'

export default {
  name: 'OfferStep1',
  components: {
    FileUploader,
    FormTextAreaLimited,
    UserInfoStyle2,
    ListingInfo,
    PropertyDueDeligencePeriodSelect,
    PropertyBuyerSecureLoan,
    PropertyDownPaymentAmount
  },
  mixins: [wizardSteps],
  inject: ['authUser'],
  props: {
    listing: {
      type: Object,
      required: true
    }
  },
  validations: {
    form: {
      amount: { required },
      downPaymentAmount: { required },
      dueDeligence: { required },
      secureLoan: { required }
    }
  },
  data () {
    return {
      formSubmitted: false,
      form: {
        amount: '',
        downPaymentAmount: '',
        dueDeligence: null,
        secureLoan: null,
        message: null
      }
    }
  },
  computed: {
    avatar () {
      return this.user.avatar ? this.user.avatar : this.defaultAvatar
    },
    user () {
      return this.authUser
    },
    error () {
      return this.$v.form.amount.required
    }
  }
}
</script>

<style lang="scss">
.preaproval-letter {
  font-size: 16px;
}

.cash-offer {
  label {
    color: var(--iq-homeiz-royal-blue) !important;
  }

  .offer-image {
    img {
      max-width: 260px;
    }
  }

  .currency-input {
    div {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
  }

  input {
    &.linear {
      &.form-control {
        width: 60%;
        text-align: center;
        padding-right: 12px !important;
        font-size: 30px !important;
        border: 1px solid transparent !important;
        border-bottom: 1px solid var(--iq-primary) !important;
        border-radius: 0 !important;

        &.is-invalid {
          border-bottom-color: #f00 !important;
        }
      }
    }
  }

  textarea {
    border-radius: 20px !important;
  }

  .btn-review {
    height: 60px;
  }
}

.terms-text {
  font-size: 11px;
}
</style>
