<template>
  <div class="mb-3 w-100">
    <b-form class="comment-text d-flex align-items-center">
      <b-form-input
        type="text"
        @keyup.enter="saveComment(commentMessage)"
        v-model="commentMessage"
        class="comment-input"
        placeholder="My comment..."
      />
      <div class="comment-attagement d-flex">
        <b-link href="javascript:void(0);">
          <i class="ri-vidicon-line mr-2"></i>
        </b-link>
        <b-link href="javascript:void(0);">
          <i class="ri-link mr-2"></i>
        </b-link>
        <b-link href="javascript:void(0);">
          <i class="ri-camera-line mr-2"></i>
        </b-link>
        <b-link>
          <i class="ri-emotion-line mr-2"></i>
        </b-link>
      </div>
    </b-form>
    <div class="comment-cancel-button">Press Q to <b-link @click="closeEditForm">cancel</b-link></div>
  </div>
</template>

<script>
import escCloseMixin from '@/mixins/escCloseMixin'
export default {
  name: 'FeedCommentEditForm',
  inject: ['authUser'],
  mixins: [escCloseMixin],
  props: {
    comment: {
      type: Object,
      required: true
    }
  },
  mounted () {
    this.commentMessage = this.comment.user.msg
  },
  data () {
    return {
      commentMessage: null
    }
  },
  methods: {
    saveComment (message) {
      // this.post.comments.push({
      //   image: require('@/assets/images/user/user-04.jpg'),
      //   user: { name: 'Sandy S', msg: message, time: new Date() },
      //   is_commentLike: false
      // })

      this.commentMessage = null
    },
    close () {
      this.closeEditForm()
    },
    closeEditForm () {
      this.$emit('close-edit-form')
    }
  },
  computed: {
  }
}
</script>

<style lang="scss" scoped>
  .comment-cancel-button {
    font-size: 12px;
  }
</style>
