<template>
  <b-row class="mt-2">
    <b-col md="12">
      <p class="text-underline" v-b-toggle.offer-history>{{ stateTitle }} cash offer history</p>
    </b-col>
    <b-col md="12">
      <b-collapse id="offer-history">
        <iq-card class="bg-light-blue">
          <b-row>
            <b-col md="12">
              <h3>Cash offer history</h3>
            </b-col>
          </b-row>
          <b-row v-for="(item,index) in [1,2,3,4,5,6]" :key="index" class="pl-2 pr-2 pb-4">
            <b-col md="8">
              <div class="media align-items-center">
                <div class="iq-profile-avatar status-online">
                  <img class="rounded-circle avatar-50" :src="user.avatar" :alt="user.fullName">
                </div>
                <div class="media-body ml-3">
                <h6 class="mb-0">Offer sent</h6>
                  <p class="mb-0">You - Jan. 18</p>
                </div>
              </div>
            </b-col>
            <b-col class="d-flex align-items-center" md="4"><b>$2,500,000</b></b-col>
          </b-row>
        </iq-card>
      </b-collapse>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'CashOfferHistory',
  inject: ['authUser'],
  data () {
    return {
      collapsed: true
    }
  },
  mounted () {
    this.$root.$on(
      'bv::collapse::state',
      (id, collapsed) => {
        if (id === 'offer-history') {
          this.collapsed = collapsed
        }
      })
  },
  computed: {
    avatar () {
      return this.user.avatar ? this.user.avatar : this.defaultAvatar
    },
    user () {
      return this.authUser
    },
    stateTitle () {
      return this.collapsed ? 'Close' : 'View'
    }
  }
}
</script>

<style lang="scss">
</style>
